import React, { useEffect, useState } from 'react';

// import global style
import './Home.css';
import '../../styles/global.css'

// Import Components
import SocialLink from '../../components/SocialLink/SocialLink';
import ExperienceCard from '../../components/ExperienceCard/ExperienceCard';
import headshotImg from '../../assets/images/headshot.jpeg';
import lenexacareImg from '../../assets/images/Lenexa/Lenexa Care.jpg';
import go1 from '../../assets/images/Engineering/go1.jpg';
import nana from '../../assets/images/Life/Dogs/Nana.jpg';
import remarkables from '../../assets/images/Skiing/remarkable-mountain-top.jpg';

const HomePage = () => {
    const typingText = "I'm an Embedded Systems Engineer navigating the world of mechanics, sensors, and software, with a love for robotics. Check out my website!";
    const words = typingText.split(' ');
    const [typedText, setTypedText] = useState('');
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [showCursor, setShowCursor] = useState(true);

    useEffect(() => {
        if (currentWordIndex < words.length) {
        const timeout = setTimeout(() => {
            setTypedText((prevText) => prevText + words[currentWordIndex] + ' ');
            setCurrentWordIndex(currentWordIndex + 1);
        }, 150); // Adjust speed to match the HTML version
        return () => clearTimeout(timeout);
        } else {
        setShowCursor(false);
        }
    }, [currentWordIndex, words]);

    return (
      <div>
        {/* Intro Section */}
        <section className="intro-section">
          <div className="intro-content">
            <img src={headshotImg} alt="Yun Chu" className="profile-img" />
            <div className="intro-text">
                <h1>Hello, I'm Yun Chu</h1>
                <h2 className="typewriter">
                {typedText}
                {showCursor && <span className="cursor" />}
                </h2>
            </div>
          </div>
        </section>

      {/* Engineering Journey Section */}
      <main>
        <section className="Home-main">
        <section id="engineering" className="section-preview">
          <h2 className="section-title">Engineering Journey</h2>
          <div className="engineering-experience-cards">
            <ExperienceCard
              title="Embedded Systems Engineer @Lenexa Medical"
              duration="Nov 2023 - Present"
              imageSrc={lenexacareImg}
              journey="I began my journey at Lenexa in November 2023 as an Engineering Intern as a part of my degree. After completing the summer internship, I advanced to the role of Embedded Systems Engineer. In this capacity, I am responsible for managing the firmware on data acquisition devices as well as developing and maintaining the data analysis software."
              description="Lenexa Medical is dedicated to helping prevent pressure injuries in patients at aged care centres and hospitals. The company leverages advanced technologies such as computer vision, fabric-based pressure sensors, and cloud computing to deliver alerts, reports, and records, thereby helping healthcare organisations optimise manpower and precisely identify patients needing immediate attention."
              responsibilities={[
                'Responsible for the firmware development of data acquisition devices, integrating various sensor technologies.',
                'Worked on the cloud-based data analysis software to provide real-time and predictive insights for healthcare facilities.',
                'Collaborated with cross-functional teams to improve the efficiency of data transmission and alert accuracy.'
              ]}
              skills={['Embedded Systems Development', 'Firmware Engineering', 'Data Acquisition', 'Cloud Computing', 'Cross-Functional Collaboration']}
              link="/engineering/lenexa"
            />
            <ExperienceCard
              title="Master of Mechatronics Engineering @University of Melbourne"
              duration="Jul 2022 - Jul 2024"
              imageSrc={go1}
              journey="In 2022, I relocated to Melbourne to pursue my Master's degree in Mechatronics Engineering, a field that aligns perfectly with my passion for robotics and automation. 
                                During my studies, I developed a deep interest in artificial intelligence (AI) and its application in robotics for control and decision-making. 
                                This motivated me to join the robotics capstone project team, where I gained hands-on experience in robot control and research methodologies. 
                                My Master's journey equipped me with skills in ROS, computer vision, and firmware development, solidifying my expertise in these domains."
              description="The Master of Mechatronics Engineering is a comprehensive 300-point program that integrates computer science, mechanical engineering, and electronics. 
                                It leverages resources from multiple disciplines within the Faculty of Engineering and Information Technology to provide students with cross-domain expertise and advanced knowledge, preparing them for real-world challenges in robotics and automation."
              responsibilities={[
                'Developed a path-planning algorithm for the Unitree Go1 quadruped robot\'s navigation control, utilising ROS, an Intel Realsense D455 camera, and Jetson Nano/Raspberry Pi. Successfully demonstrated the system at the Endeavour Exhibition.',
                'Designed and implemented obstacle navigation for the Kobuki robot, showcasing effective control systems in challenging environments with sensors and embedded software development.',
                'Achieved honour standard in advanced subjects, including Computer Vision, AI for Robotics, AI for Engineers, and Machine Learning.'
              ]}
              skills={['Artificial Intelligence', 'Robotics', 'Embedded Systems']}
              link="#"
            />
          </div>
        </section>
        </section>
      </main>

      {/* Personal Life Section */}
      <section id="personal-life" className="color-section">
        <div className="full-width-bg">
          <h2 className="section-title">Personal Life</h2>
          <div className="personal-life-grid">
            <PersonalCard
              title="Favorite Slopes"
              description="Exploring the best ski resorts and sharing my experiences."
              imageSrc={remarkables}
              link="/skiing"
            />
            <PersonalCard
              title="Life with Dogs"
              description="Exploring my adventures with my dogs and sharing their stories."
              imageSrc={nana}
              link="dogs.html"
            />
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="contact-section">
        <div className="white-section">
          <h2 className="section-title">Get in Touch</h2>
          <p>Feel free to reach out for collaborations or just to say hi!</p>
          <p>Email: yun.chu.au@gmail.com</p>
          <SocialLink/>
        </div>
        
      </section>
    </div>
  );
};

// PersonalCard Component (kept inline as it's specific to this page)
const PersonalCard = ({ title, description, imageSrc, link }) => (
  <div className="personal-life-card">
    <div className="preview-image-container">
      <img src={imageSrc} alt={title} className="preview-image" />
    </div>
    <div className="preview-content">
      <h3>{title}</h3>
      <p>{description}</p>
      <a href={link} className="read-more">Read More</a>
    </div>
  </div>
);

export default HomePage;