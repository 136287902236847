import React from 'react';
import TravelBlogPage from '../../components/TravelBlog/TravelBlogPage';

import LittleNationalHotelSydneyRoomImg from '../../assets/images/Travel/Sydney/LittleNationalHotelSydneyRoom.jpg';
import LittleNationalHotelSydneyRooftopImg from '../../assets/images/Travel/Sydney/LittleNationalHotelSydneyRooftop.jpg';
import BridgeAndOperaHouseImg from '../../assets/images/Travel/Sydney/BridgeAndOperaHouse.jpg';
import KingfishTartarImg from '../../assets/images/Travel/Sydney/KingfishTartar.jpg';
import BinChickenImg from '../../assets/images/Travel/Sydney/BinChicken.jpg';
import SydneyFishMarketImg from '../../assets/images/Travel/Sydney/SydneyFishMarket.jpg';
import SydneyRoadViewImg from '../../assets/images/Travel/Sydney/SydneyRoadView.jpg';
import SydneyUniImg from '../../assets/images/Travel/Sydney/SydneyUni.jpg';
import UglyPlushImg from '../../assets/images/Travel/Sydney/UglyPlush.jpg';
import OperaHouseImg from '../../assets/images/Travel/Sydney/opera_house.jpg';


const accommodations = [
  {
    // title: "Seaside Resort",
    topic: "Little National Hotel Sydney",
    description: "相對現代豪華的商旅。內裝新穎，服務很好。健身房有點普通，沒有游泳池。整體而言，打折時可以來住住看，沒打折有點貴。",
    bulletPoints: [
      "地址: 26 Clarence St, Sydney NSW 2000",
      "推薦指數: 4.5/5"
    ],
    images: [
      { src: LittleNationalHotelSydneyRoomImg, alt: "Hotel" },
      { src: LittleNationalHotelSydneyRooftopImg, alt: "Rooftop" },
    ]
  }
];


const TravelBlog = () => {
  const greatOceanRoadTripData = {
    title: "Sydney三日游",
    subtitle: "藍山、歌劇院、繁華街區",
    accommodation: accommodations, // Use a colon instead of an equals sign
    
    featuredImage: {
      src: BridgeAndOperaHouseImg,
      alt: "BridgeAndOperaHouseImg",
      caption: "雪梨大橋與歌劇院，簡直就是地標本人"
    },
    
    journeyIntro: "早訪Jimmy的行程。我們安排了三日的雪梨行程，其中一天要去藍山逛逛。",
    
    // Updated to object format for the PhotoGallery component
    galleryImages: [
      
      {
        src: KingfishTartarImg,
        alt: "KingfishTartar",
        caption: "很好吃的King fish塔塔小點"
      },
      {
        src: SydneyFishMarketImg,
        alt: "SydneyFishMarket",
        caption: "雪梨魚市場，規模不大，但裡面很多皇帝蟹"
      },
      {
        src: OperaHouseImg,
        alt: "OperaHouse",
        caption: "歌劇院"
      },
      {
        src: UglyPlushImg,
        alt: "UglyPlush",
        caption: "超醜的娃娃，到底誰會買這個"
      }
    ],
    
    travelPlan: [
      {
        day: 1,
        activities: [
          {text: '抵達雪梨，第一日的活動也不算太多，逛逛雪梨歌劇院、雪梨魚市場、雪梨大學後，前往Habour旁的高級餐廳6 Heads就餐。價格非常昂貴，風景非常好，感謝Jimmy請客。' },
          {text: '晚上入住的Little National Hotel Sydney非常高級，不過空間很小。我們喝了一堆房間內的咖啡。' }
        ]
      },
      {
        day: 2,
        activities: [
          {text: '第二天殺去藍山，雪梨著名景點之一。一個半小時的車程，我們抵達了。'},
          {text: '藍山最著名的就是透明地板纜車，以及漫步於山林間的壯觀山景。下雪時來應該很美。'},
          {text: '搭乘了世界上最陡峭的鐵軌車，看看過往採礦時的交通工具。'},
          {text: '再參觀了一下擺放道具恐龍的步道，有點莫名，但就是逛完了。然後就回家啦'}
        ]
      },
      {
        day: 3,
        activities: [
          {text: '第三天的一早就搭乘飛機前往布里斯本，記在另一篇遊記中。'}
        ]
      }
    ],
    
    // You can also replace highlights with another PhotoGallery if desired.
    // For consistency, we'll convert highlightImages to the new format as well.
    highlights: {
      text: "雪梨的街景可以看到好多垃圾雞，滿有趣的。繁華的城市其實跟墨爾本不算差距太大，有點像亞洲城市，但更為整潔舒服。雪梨大學大概是這次的重點之一，真的很漂亮。",
      
    },
    highlightsGalleryImages: [
      {
        src: BinChickenImg,
        alt: "BinChickenImg",
        caption: "到處都是垃圾雞!"
      },
      {
        src: SydneyRoadViewImg,
        alt: "SydneyRoadViewImg",
        caption: "繁華的城市景象"
      },
      {
        src: SydneyUniImg,
        alt: "SydneyUniImg",
        caption: "雪梨大學，根本霍格華茲"
      },
    ],


    
    finalThoughts: "雪梨的城市繁華如耳聞，確實步伐較快，較為繁華。感覺附近可以去的景點沒有墨爾本多，不過也很不錯了。寫這篇的時候，我正在規劃再次造訪。去過後再補上第二度造訪心得。",
    
    author: {
      name: "Yun Chu",
      date: "2024/12/8"
      // image: "/api/placeholder/80/80",
      // bio: "Embedded Systems Engineer and adventure enthusiast. Always seeking new experiences and sharing stories along the way."
    }
  };

  return <TravelBlogPage {...greatOceanRoadTripData} />;
};

export default TravelBlog;
