import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Timeline from '../../components/Timeline/Timeline';
import EducationItem from '../../components/EducationItem/EducationItem';
import './Education.css';
import unimelbLogo from '../../assets/images/Education/unimelb.jpg';
import ntuLogo from '../../assets/images/Education/ntu_logo.png';
import ahsnccuLogo from '../../assets/images/Education/ahsnccu.png';
import zneesLogo from '../../assets/images/Education/znees.jpeg';

const educationData = [
  {
    id: 1,
    institutionEn: 'The University of Melbourne',
    institutionZh: '墨爾本大學',
    start: '2022-07',
    end: '2024-07',
    startYear: 2022,
    startMonth: 7,
    endYear: 2024,
    endMonth: 7,
    yearRange: '2022 - 2024',
    degree: 'Master of Mechatronics Engineering (with Distinction)',
    logo: unimelbLogo,
    officialInfo: 'Certificate Issued by The University of Melbourne: click <a href="https://www.myequals.net/sharelink/ade6f0cc-9fb3-455e-b7f9-9f8b4f040b04/c55bbbeb-e26d-492e-a5b4-190ede3beba3" target="_blank" rel="noopener noreferrer">here</a> to open',
    extracurricular: [
      '2023 Student Ambassador of the Faculty of Engineering and Information Technology',
      'FEIT Mentoring Program',
      'MUEEC Student Member'
    ],
    capstone: [
      'Supervisor: Dr Ye Wang',
      'Co-supervisor: Prof Chris Manzie',
      'Topic: Quadrapped Robot Navigation and Control'
    ],
    achievements: [
      '2023 Ken Eltham Scholarship'
    ]
  },
  {
    id: 2,
    institutionEn: 'National Taiwan University',
    institutionZh: '國立台灣大學',
    start: '2017-09',
    end: '2022-01',
    startYear: 2017,
    startMonth: 9,
    endYear: 2022,
    endMonth: 1,
    yearRange: '2017 - 2022',
    degree: 'Bachelor of Science (Mechanical Engineering)',
    logo: ntuLogo,
    officialInfo: '',
    extracurricular: [
      'System Control and Mechatronics Specialization'
    ],
    research: [
      'Research Project: Vertical Driven Model for Spherical Robot',
      'Lab: NTU Bio-inspired Robotics Laboratory (BioRola)',
      'Supervisor: Prof Pei Chun Lin',
      'Teammates: 蕭尚亞, 王華豫, and 陳韜'
    ],
    subjects: [
      'System Dynamics',
      'Digital Control System',
      'Machine Learning',
      'etc'
    ]
  },
  {
    id: 3,
    institutionEn: 'The Affiliated High School of National Chengchi University (AHSNCCU)',
    institutionZh: '政大附中(高中部)',
    start: '2014-09',
    end: '2017-06',
    startYear: 2014,
    startMonth: 9,
    endYear: 2017,
    endMonth: 6,
    yearRange: '2014 - 2017',
    degree: 'High School: Science and Mathematics Focus (level 10-12)',
    logo: ahsnccuLogo,
    officialInfo: '',
    extracurricular: [
      '導師: 曹靜茹 老師',
      '數理資優班 Gifted and Talented Program',
      '物理專提研究(黃立雲老師指導) Physics Research Projects',
      '聞了很多鉛筆芯燃燒的味道'
    ],
    research: [],
    subjects: [],
    additionalInfo: []
  },
  {
    id: 4,
    institutionEn: 'The Affiliated High School of National Chengchi University (AHSNCCU)',
    institutionZh: '政大附中(國中部)',
    start: '2011-09',
    end: '2014-06',
    startYear: 2011,
    startMonth: 9,
    endYear: 2014,
    endMonth: 6,
    yearRange: '2011 - 2014',
    degree: 'Junior High School: General Education (level 7-9)',
    logo: ahsnccuLogo,
    officialInfo: '',
    extracurricular: [
      '導師: 張彥勝 老師',
      '第7屆',
      'Judo Team Vice Leader 政大附中柔道隊副隊長',
      '校級幹部:大隊指揮',
      '第一屆會考:5A++;作文5級'
    ],
    research: [],
    subjects: [],
    additionalInfo: []
  },
  {
    id: 5,
    institutionEn: 'Taipei Zhinan Experimental Elementary School',
    institutionZh: '指南國小',
    start: '2005-08',
    end: '2011-06',
    startYear: 2005,
    startMonth: 8,
    endYear: 2011,
    endMonth: 6,
    yearRange: '2005 - 2011',
    degree: 'Elementary School: Primary Education',
    logo: zneesLogo,
    officialInfo: '',
    extracurricular: [
      '指南山的放山雞'
    ],
    research: [],
    subjects: [
      '水質檢測專題'
    ],
    additionalInfo: [
      "It's a tiny school full of fun and love."
    ]
  }
];

const EducationPage = () => {
  const [activeId, setActiveId] = useState(null);

  const handleSelect = (id) => {
    setActiveId((prevId) => (prevId === id ? null : id));
    const element = document.getElementById(`education-${id}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="page-container">

      {/* Header */}
      <Header title="Education History" subtitle="My Academic Journey - From Taipei to Melbourne" />

      {/* Timeline Section */}
      <Timeline educationData={educationData} activeId={activeId} onSelect={handleSelect} />

      {/* Education Items Container */}
      <div className="container">
        <div className="text-section">
          {educationData.map((edu) => (
            <EducationItem
              key={edu.id}
              education={edu}
              isActive={activeId === edu.id}
              onSelect={handleSelect}
            />
          ))}
        </div>
      </div>

    </div>
  );
};

export default EducationPage;
