import React from 'react';
import './SocialLink.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const SocialLink = () => {
    return (
        <div className="social-links">
            <a href="#"><FontAwesomeIcon icon={faGithub} /></a>
            <a href="https://www.linkedin.com/in/yunchu1999/"><FontAwesomeIcon icon={faLinkedin} /></a>
            <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
            <a href="#"><FontAwesomeIcon icon={faInstagram} /></a>
        </div>
    );
  };
  
  export default SocialLink;
  