import React from 'react';
import TravelBlogPage from '../../components/TravelBlog/TravelBlogPage';

import KookaburraImg from '../../assets/images/Travel/Moonlit/Kookaburra.jpg';
import KangarooImg from '../../assets/images/Travel/Moonlit/Kangaroo.jpg';
import DingoImg from '../../assets/images/Travel/Moonlit/Dingo.jpg';
import DevilImg from '../../assets/images/Travel/Moonlit/Devil.jpg';
import WallabyFeedingHankImg from '../../assets/images/Travel/Moonlit/WallabyFeedingHank.jpg';
import WallabyFeedingImg from '../../assets/images/Travel/Moonlit/WallabyFeeding.jpg';
import PinkCockatooImg from '../../assets/images/Travel/Moonlit/PinkCockatoo.jpg';
import KoalaImg from '../../assets/images/Travel/Moonlit/Koala.jpg';
import Kookaburra2Img from '../../assets/images/Travel/Moonlit/Kookaburra-2.jpg';

const accommodations = [
];


const MoonlitTravelBlog = () => {
  const MoonlitTripData = {
    title: "Moonlit野生動物保育區-與Kookaburra相遇",
    subtitle: "近距離接觸小動物&袋鼠餵食",
    accommodation: accommodations, // Use a colon instead of an equals sign
    
    featuredImage: {
      src: KookaburraImg,
      alt: "Kookaburra",
      caption: "Kookaburra笑翠鳥"
    },
    
    journeyIntro: "前一天去看了小企鵝歸巢，第二天回程來到這裡。Moonlit的評價很高，而且有很多跟野生動物互動的機會。在澳洲住了一陣子後，發現越來越喜歡那些本來不會特別注意的小動物，例如quoll跟Kookaburra。於是，我們預約了Kookaburra Encounter，來餵這群可愛鳥類。",
    
    // Updated to object format for the PhotoGallery component
    galleryImages: [
      
      {
        src: KangarooImg,
        alt: "Kangaroo",
        caption: "Kangaroo!"
      },
      {
        src: DingoImg,
        alt: "Dingo",
        caption: "Dingo澳洲野犬，長得很像大型柴犬"
      },
      {
        src: DevilImg,
        alt: "Devil",
        caption: "Tasmania Devil"
      },
      {
        src: WallabyFeedingHankImg,
        alt: "WallabyFeeding",
        caption: "餵食Wallaby"
      },
      {
        src: WallabyFeedingImg,
        alt: "WallabyFeeding",
        caption: "餵食Wallaby"
      }
    ],
    
    travelPlan: [
      {
        day: 1,
        activities: [
          {text: '從菲立普島開過來花了一個小時。抵達後，我們先四處逛逛。入園處可以購買袋鼠飼料，園區內有很大一片草地有一堆袋鼠可以餵食。' },
          {text: '前往Kookaburra互動活動前，我們也參加了免費的導覽。看到粉紅鸚鵡、蟒蛇等動物，體驗很不錯。' },
          {text: 'Kookaburra Encounter本身大約15分鐘，是一個私人導覽活動。解說員帶我們走進他們的生活區，讓牠們飛到我們手上，並且給我們肉塊餵食。Kookaburra的腳意外得很柔軟，並沒有被抓痛的感覺。' },
          {text: '結束後，跑去遊客中心喝了碗南瓜湯，逛了逛紀念品，就準備回家了。' }
        ]
      }
    ],
    
    // You can also replace highlights with another PhotoGallery if desired.
    // For consistency, we'll convert highlightImages to the new format as well.
    highlights: {
      text: "kookaburra真的很可愛，",
      
    },
    highlightsGalleryImages: [
      {
        src: PinkCockatooImg,
        alt: "PinkCockatoo",
        caption: "粉紅鸚鵡秀，真的很聰明靈動"
      },
      {
        src: KoalaImg,
        alt: "Koala",
        caption: "園區內的無尾熊，感覺是動物園標配"
      },
      {
        src: Kookaburra2Img,
        alt: "Kookaburra",
        caption: "Kookaburra!"
      }
    ],


    
    finalThoughts: "Moonlit是我在澳洲待得兩年來去過最好玩的動物園/保育區。與野生動物的互動設計極好，園區內的整潔也很棒(不像有些園區一堆袋鼠大便)整體而言，大推!",
    
    author: {
      name: "Yun Chu",
      date: "2024/12/8"
      // image: "/api/placeholder/80/80",
      // bio: "Embedded Systems Engineer and adventure enthusiast. Always seeking new experiences and sharing stories along the way."
    }
  };

  return <TravelBlogPage {...MoonlitTripData} />;
};

export default MoonlitTravelBlog;
