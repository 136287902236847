import React from 'react';

// ExperienceCard Component
const ExperienceCard = ({ 
  title, 
  duration, 
  imageSrc, 
  journey, 
  description, 
  responsibilities, 
  skills, 
  link 
}) => (
  <div className="experience-card">
    <div className="experience-top">
      <div className="experience-image-container-vertical">
        <img src={imageSrc} alt={title} className="experience-image" />
      </div>
      <div className="experience-company-description">
        <h3>{title}</h3>
        <p className="experience-duration">{duration}</p>
        <p><strong>My Journey</strong></p>
        <p>{journey}</p>
      </div>
    </div>
    <div className="experience-bottom">
      <div className="experience-my-journey">
        <p><strong>About</strong></p>
        <p>{description}</p>
      </div>
      <div className="experience-responsibilities">
        <p><strong>Key Information</strong></p>
        <ul className="experience-details">
          {responsibilities.map((res, index) => (
            <li key={index}>{res}</li>
          ))}
        </ul>
      </div>
    </div>
    <div className="experience-bottom-links">
      <a href={link} className="read-more">Read More →</a>
      <div className="key-skills-container">
        {skills.map((skill, index) => (
          <span key={index} className="skill-tag">{skill}</span>
        ))}
      </div>
    </div>
  </div>
);

export default ExperienceCard;