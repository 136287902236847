import React from 'react';
import Header from '../../components/Header/Header';

import projectsData from './projects.json';  // Import the JSON file
import researchProjectsData from './researchProjects.json';  // Import the JSON file
import skillsData from './skills.json';

import ExperienceCard from '../../components/ExperienceCard/ExperienceCard';
import lenexacareImg from '../../assets/images/Lenexa/Lenexa Care.jpg';


import './Engineering.css'
import '../../styles/global.css';

// Skill Card Component
const SkillCard = ({ title, description }) => (
  <div className="skill-card">
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

// Project Card Component
const ProjectCard = ({ 
  imageSrc, 
  title, 
  tags, 
  description, 
  linkHref 
}) => (
  <div className="project-card">
    <div className="project-image-container">
      <img src={imageSrc} alt={title} className="project-image" />
    </div>
    <div className="project-content">
      <h3>{title}</h3>
      <div className="project-tags">
        {tags.map((tag, index) => (
          <span key={index} className="project-tag">{tag}</span>
        ))}
      </div>
      <p>{description}</p>
      <a href={linkHref}>Read More</a>
    </div>
  </div>
);

// Achievement Item Component
const AchievementItem = ({ title, description }) => (
  <li className="achievement-item">
    <h3>{title}</h3>
    <p>{description}</p>
  </li>
);

const EngineeringPage = () => {

  const skills = skillsData.skills
  const projects = projectsData.projects.map(project => ({
    ...project,
    imageSrc: require(`../../assets/images/Engineering/${project.imageSrc}`)
  }));

  const researchProject = researchProjectsData.projects.map(project => ({
    ...project,
    imageSrc: require(`../../assets/images/Engineering/${project.imageSrc}`)
  }));

  // Achievements Data
  const achievements = [
    {
      title: "Team Lead for Robotics Competition",
      description: "Led a team to victory in the National Robotics Competition, showcasing advanced automation techniques."
    }
  ];

  return (
    <div className="main">

      <Header 
        title="Engineering Journey" 
        subtitle="Learn by doing it, including this website! I literarly had no experience in building a website before."
      />

      {/*Lenexa Medical Section*/}
      <section className = "white-section">
        <h2>Professional Experiences</h2>
        <div className="section-container">
          <ExperienceCard
            title="Embedded Systems Engineer @Lenexa Medical"
            duration="Nov 2023 - Present"
            imageSrc={lenexacareImg}
            journey="I began my journey at Lenexa in November 2023 as an Engineering Intern as a part of my degree. After completing the summer internship, I advanced to the role of Embedded Systems Engineer. In this capacity, I am responsible for managing the firmware on data acquisition devices as well as developing and maintaining the data analysis software."
            description="Lenexa Medical is dedicated to helping prevent pressure injuries in patients at aged care centres and hospitals. The company leverages advanced technologies such as computer vision, fabric-based pressure sensors, and cloud computing to deliver alerts, reports, and records, thereby helping healthcare organisations optimise manpower and precisely identify patients needing immediate attention."
            responsibilities={[
              'Responsible for the firmware development of data acquisition devices, integrating various sensor technologies.',
              'Worked on the cloud-based data analysis software to provide real-time and predictive insights for healthcare facilities.',
              'Collaborated with cross-functional teams to improve the efficiency of data transmission and alert accuracy.'
            ]}
            skills={['Embedded Systems Development', 'Firmware Engineering', 'Data Acquisition', 'Cloud Computing', 'Cross-Functional Collaboration']}
            link="/engineering/lenexa"
          />
        </div>
      </section>

      {/*Lenexa Medical Section*/}
      <section className = "color-section">
        <h2>Research Project</h2>
        <div className="projects-grid">
          {researchProject.map((researchProject, index) => (
            <ProjectCard 
              key={index}
              imageSrc={researchProject.imageSrc}
              title={researchProject.title}
              tags={researchProject.tags}
              description={researchProject.description}
              linkHref={researchProject.linkHref}
            />
          ))}
        </div>
      </section>

      {/* Skills Section */}
      <section className="white-section">
        <h2>Skills</h2>
        <div className="skills-grid">
          {skills.map((skill, index) => (
            <SkillCard 
              key={index}
              title={skill.title}
              description={skill.description}
            />
          ))}
        </div>
      </section>

      {/* Projects Section */}
      <section className="color-section">
        <h2>Projects</h2>
        <div className="projects-grid">
          {projects.map((project, index) => (
            <ProjectCard 
              key={index}
              imageSrc={project.imageSrc}
              title={project.title}
              tags={project.tags}
              description={project.description}
              linkHref={project.linkHref}
            />
          ))}
        </div>
      </section>

      {/* Achievements Section */}
      <section className="achievements-section">
        <h2>Achievements</h2>
        <ul className="achievements-list">
          {achievements.map((achievement, index) => (
            <AchievementItem
              key={index}
              title={achievement.title}
              description={achievement.description}
            />
          ))}
        </ul>
      </section>
    </div>
  );
};

export default EngineeringPage;