import React from 'react';
import Header from '../Header/Header';
import './TravelBlogPage.css';

import PhotoGallery from '../../components/PhotoGallery/PhotoGallery';

const TravelBlogPage = ({
  title,
  subtitle,
  featuredImage,
  journeyIntro,
  galleryImages = [],
  highlightsGalleryImages = [],
  travelPlan,
  highlights,
  accommodation = [],
  travelTips = [],
  finalThoughts,
  author
}) => {
  return (
    <div>
      <Header 
        title={title}
        subtitle={subtitle}
      />
      <main className="blog-container">
        <article>
          {/* Featured Image Section */}
          <div className="featured-image-container">
            <img 
              src={featuredImage.src}
              alt={featuredImage.alt}
              className="featured-image" 
            />
            <div className="image-caption">
              {featuredImage.caption}
            </div>
          </div>

          {/* Journey Begin Section */}
          <div className="blog-content">
            <section className="blog-section">
              <h2>The Journey Begins</h2>
              <p>{journeyIntro}</p>
            </section>
          </div>

          {/* Photo Gallery Section */}
          {galleryImages.length > 0 && (
            <PhotoGallery images={galleryImages} />
          )}

          {/* Travel Plan Section */}
          <section className="blog-section">
            <h2>Travel Plan</h2>
            {travelPlan.map((dayPlan) => (
              <div key={dayPlan.day} className="day-plan">
                <h3>Day {dayPlan.day}</h3>
                <ol>
                  {dayPlan.activities.map((activity, index) => (
                    <li key={index}>
                      <i className={`fas fa-${activity.icon}`}></i>
                      {activity.text}
                    </li>
                  ))}
                </ol>
              </div>
            ))}
          </section>

          {/* Highlights Section */}
          <section className="blog-section">
            <h2>Highlights</h2>
            <p>{highlights.text}</p>
            {highlightsGalleryImages.length > 0 && (
              <PhotoGallery images={highlightsGalleryImages} />
            )}
          </section>

          {/* Accommodation Section */}
          {accommodation.length > 0 && (
            <section className="blog-section">
              <h2>Where We Stayed</h2>
              {accommodation.map((acc, index) => (
                <div key={index} className="accommodation-plan">
                  <h3>{acc.title}</h3>
                  {acc.topic && <h4>{acc.topic}</h4>}
                  {acc.description && <p>{acc.description}</p>}
                  {acc.bulletPoints && acc.bulletPoints.length > 0 && (
                    <ul className="accommodation-bullets">
                      {acc.bulletPoints.map((point, i) => (
                        <li key={i}>{point}</li>
                      ))}
                    </ul>
                  )}
                  {acc.images && acc.images.length > 0 && (
                    <div className="photo-gallery">
                      {acc.images.map((img, i) => (
                        <img
                          key={i}
                          src={img.src}
                          alt={img.alt || `Accommodation image ${i + 1}`}
                          className="gallery-image"
                        />
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </section>
          )}


          {/* Travel Tips Section */}
          {travelTips.length > 0 && (
            <div className="travel-tips">
              <h2>Travel Tips</h2>
              {travelTips.map((tip, index) => (
                <div key={index} className="tip-item">{tip}</div>
              ))}
            </div>
          )}

          {/* Final Thoughts Section */}
          <section className="blog-section">
            <h2>Thoughts and Summary</h2>
            <p>{finalThoughts}</p>
          </section>

          {/* Author Section */}
          <div className="author-section">
            {/* <img 
              src={author.image || '/api/placeholder/80/80'} 
              alt={author.name} 
              className="author-image" 
            /> */}
            <div className="author-info">
              <h4>Written by {author.name}</h4>
              <h5>Last update: {author.date}</h5>
              <p>{author.bio}</p>
            </div>
          </div>

          {/* Share Section */}
          {/* <div className="share-section">
            <h3>Share this adventure</h3>
            <div className="share-buttons">
              <a href="#" className="share-button"><i className="fab fa-facebook"></i></a>
              <a href="#" className="share-button"><i className="fab fa-twitter"></i></a>
              <a href="#" className="share-button"><i className="fab fa-pinterest"></i></a>
              <a href="#" className="share-button"><i className="fab fa-linkedin"></i></a>
            </div>
          </div> */}
        </article>
      </main>
    </div>
  );
};

export default TravelBlogPage;
