// TravelCard.js
import React from 'react';
import './TravelCard.css';

const TravelCard = React.forwardRef((props, ref) => {
  const {
    imageSrc,
    imageAlt,
    date,
    title,
    excerpt,
    tags,
    readMoreLink,
  } = props;

  return (
    <div className="travel-card" ref={ref}>
      <div className="travel-image">
        {imageSrc ? (
          <img src={imageSrc} alt={imageAlt} />
        ) : (
          <div className="placeholder-image">{imageAlt}</div>
        )}
      </div>
      <div className="travel-content">
        <div className="travel-date">{date}</div>
        <h3 className="travel-title">{title}</h3>
        <p className="travel-excerpt">{excerpt}</p>
        <div className="travel-tags">
          {tags.map((tag, index) => (
            <span key={index} className="travel-tag">
              {tag}
            </span>
          ))}
        </div>
        <a href={readMoreLink} className="travel-read-more">
          Read More
        </a>
      </div>
    </div>
  );
});

export default TravelCard;
