import React, { useRef, useEffect, useState } from 'react';
import './Timeline.css';

const Timeline = ({ educationData, activeId, onSelect }) => {
  // Sort educationData in descending order (latest first)
  const sortedEducationData = [...educationData].sort((a, b) => {
    const aStart = new Date(a.startYear, a.startMonth - 1);
    const bStart = new Date(b.startYear, b.startMonth - 1);
    return bStart - aStart;
  });

  // Function to calculate position and height based on date
  const calculatePositionAndHeight = (startYear, startMonth, endYear, endMonth, timelineHeight) => {
    const startYearOverall = 2005;
    const endYearOverall = 2024;
    
    const totalMonthsInTimeline = (endYearOverall - startYearOverall) * 12;

    const startMonthsSinceStart = (startYear - startYearOverall) * 12 + (startMonth-1);
    const endMonthsSinceStart = (endYear - startYearOverall) * 12 + (endMonth-1);

    const durationMonths = endMonthsSinceStart - startMonthsSinceStart;
    const heightPerMonth = timelineHeight / totalMonthsInTimeline;
    
    const height = durationMonths * heightPerMonth;

    const topPosition = timelineHeight - (startMonthsSinceStart * heightPerMonth) - height;

    return { topPosition, height };
  };

  const timelineRef = useRef(null);
  const [timelineHeight, setTimelineHeight] = useState(0);

  useEffect(() => {
    if (timelineRef.current) {
      const computedHeight = timelineRef.current.clientHeight;
      
      setTimelineHeight(computedHeight*0.7);
    }

    // Update timelineHeight on window resize
    const handleResize = () => {
      if (timelineRef.current) {
        const computedHeight = timelineRef.current.clientHeight;
        setTimelineHeight(computedHeight*0.7);
      }
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="timeline-section" ref={timelineRef}>
      <div className="timeline">
        {timelineHeight > 0 && sortedEducationData.map((edu) => {
          const { topPosition, height } = calculatePositionAndHeight(
            edu.startYear,
            edu.startMonth,
            edu.endYear,
            edu.endMonth,
            timelineHeight
          );

          return (
            <div
              key={edu.id}
              className={`timeline-item ${activeId === edu.id ? 'active' : ''}`}
              style={{ top: `${topPosition}px` }}
              onClick={() => onSelect(edu.id)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  onSelect(edu.id);
                }
              }}
              aria-expanded={activeId === edu.id}
            >
              <div
                className={`timeline-period ${activeId === edu.id ? 'active' : ''}`}
                style={{ height: `${height}px` }}
              ></div>
              <div className={`timeline-content ${activeId === edu.id ? 'active' : ''}`}>
                <h3>{edu.institutionEn}</h3>
                <p>{edu.yearRange}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Timeline;
