// src/components/Navigation/Navigation.js

import React, { useState } from 'react'; // Import React and useState
import { Link } from 'react-router-dom'; // Import Link once
import './Navigation.css'; // Import the CSS for Navigation

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false); // Initialize state

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle the menu open state
  };

  return (
    <div className="nav-wrapper">
      <div className="hamburger" onClick={toggleMenu}>
        ☰
      </div>
      <nav>
        <ul id="nav-links" className={menuOpen ? 'show' : ''}>
          <li>
            <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
          </li>
          <li>
            <Link to="/about" onClick={() => setMenuOpen(false)}>About</Link>
          </li>
          <li>
            <Link to="/education" onClick={() => setMenuOpen(false)}>Education</Link>
          </li>
          <li>
            <Link to="/engineering" onClick={() => setMenuOpen(false)}>Engineering</Link>
          </li>
          <li>
            <Link to="/travel" onClick={() => setMenuOpen(false)}>Travel</Link>
          </li>
          <li>
            <Link to="/skiing" onClick={() => setMenuOpen(false)}>Skiing</Link>
          </li>
          <li>
            <Link to="/life" onClick={() => setMenuOpen(false)}>Life</Link>
          </li>
          <li>
            <Link to="/contact" onClick={() => setMenuOpen(false)}>Contact</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
