import React from 'react';
import TravelBlogPage from '../../components/TravelBlog/TravelBlogPage';

import Bird2Img from '../../assets/images/Travel/Melbourne Zoo/Bird2.jpg';
import MeerkatImg from '../../assets/images/Travel/Melbourne Zoo/Meerkat.jpg';
import TasmaniaDevilImg from '../../assets/images/Travel/Melbourne Zoo/TasmaniaDevil.jpg';
import ThreeMeerkatImg from '../../assets/images/Travel/Melbourne Zoo/ThreeMeerkat.jpg';
import ThreeMeerkat2Img from '../../assets/images/Travel/Melbourne Zoo/ThreeMeerkat2.jpg';
import WombatImg from '../../assets/images/Travel/Melbourne Zoo/Wombat.jpg';
import platypusImg from '../../assets/images/Travel/Melbourne Zoo/platypus.jpg';
import MeerkatEncounterImg from '../../assets/images/Travel/Melbourne Zoo/MeerkatEncounter.jpg';
import MeerkatEncounter2Img from '../../assets/images/Travel/Melbourne Zoo/MeerkatEncounter2.jpg';


const accommodations = [
];


const TravelBlog = () => {
  const TripData = {
    title: "Melbourne Zoo與狐獴Encounter",
    subtitle: "看小狐獴解謎",
    accommodation: accommodations, // Use a colon instead of an equals sign
    
    featuredImage: {
      src: ThreeMeerkat2Img,
      alt: "ThreeMeerkat2Img",
      caption: "超可愛的狐獴們"
    },
    
    journeyIntro: "這是我們第二次造訪墨爾本動物園。目標明顯，就是衝著狐獴而來的。這次我們預約了Meerkat Encounter，是眾多Encounter活動中的一個。這個活動提供了小團私人導覽，更是有與動物直接互動的機會。Meerkat Encounter不能觸碰他們，但是可以被狐獴觸碰與拍照，於是我們就畫費了每人80塊(不含入園)左右訂了這個活動(有點貴，不過他們的收益也是照顧動物用的)，然後就殺去了。",
    
    // Updated to object format for the PhotoGallery component
    galleryImages: [
      
      {
        src: MeerkatEncounterImg,
        alt: "MeerkatEncounterImg",
        caption: "一隻狐獴好奇的跑到我腿上"
      },
      {
        src: MeerkatEncounter2Img,
        alt: "MeerkatEncounter2Img",
        caption: "狐獴迅速拆解益智遊戲，拿到食物"
      },
      {
        src: WombatImg,
        alt: "WombatImg",
        caption: "睡覺中的袋熊(wombat)"
      },
      {
        src: Bird2Img,
        alt: "Bird2Img",
        caption: "墨爾本動物園的鳥籠內可以近距離觀賞，與鳥類處與同一個空間"
      },
      {
        src: TasmaniaDevilImg,
        alt: "TasmaniaDevilImg",
        caption: "打哈欠中的塔斯馬尼亞惡魔，但其實他們超可愛"
      }
    ],
    
    travelPlan: [
      {
        day: 1,
        activities: [
          {text: '除了預約好的Meerkat Encounter外，在動物園內到處看看。' }
        ]
      }
    ],
    
    // You can also replace highlights with another PhotoGallery if desired.
    // For consistency, we'll convert highlightImages to the new format as well.
    highlights: {
      text: "感覺墨爾本動物的這次體驗最棒的部分就是與狐獴互動。解說員帶我們到後台幫狐獴們準備益智遊戲的道具，然後帶我們去看看狐獴如何迅速的用超長的前爪撕開紙袋、撥開草堆，找到食物。他們也需要轉動益智遊戲盤才得以取得藏在裡面的零食，轉盤的部分多花了一點時間。狐獴非常靈動活潑，很快就在跑到我們的椅子上，慢慢摸到我們腳上。可以看得更高更遠，真的很可愛。",
      
    },
    highlightsGalleryImages: [
      {
        src: MeerkatImg,
        alt: "MeerkatImg",
        caption: "狐獴特寫，守望者站哨中"
      },
      {
        src: platypusImg,
        alt: "platypusImg",
        caption: "鴨嘴獸，平常根本看不到，據說只在最乾淨的水域出沒"
      },
      {
        src: ThreeMeerkatImg,
        alt: "ThreeMeerkatImg",
        caption: "三個小東西"
      },
    ],


    
    finalThoughts: "墨爾本動物園其實本身規模不太大，也有很多動物常常不在。我們這次去參加的Meerkat Encounter是我們第一次近距離與狐獴接觸，也算是非常特別的體驗。如果能夠常常撥空去Zoo Victoria的合作動物園，還是可以考慮購買會員。不然感覺來一次四十幾塊好貴。",
    
    author: {
      name: "Yun Chu",
      date: "2024/12/9"
      // image: "/api/placeholder/80/80",
      // bio: "Embedded Systems Engineer and adventure enthusiast. Always seeking new experiences and sharing stories along the way."
    }
  };

  return <TravelBlogPage {...TripData} />;
};

export default TravelBlog;
