import React, { useState, useEffect, useRef } from 'react';
import './PhotoGallery.css';

const PhotoGallery = ({ images = [] }) => {
  const hasImages = images && images.length > 0;

  const viewportRef = useRef(null);
  const [viewportWidth, setViewportWidth] = useState(0);
  const [displayIndex, setDisplayIndex] = useState(0);

  // Calculate slide dimensions based on viewport width
  // For example, slideWidth = 60% of viewport, gap via margin = 5% of viewport each side
  const slideWidthRatio = 0.6;   // 60% of viewport
  const slideMarginRatio = 0.05; // 5% of viewport on each side
  const slideWidth = viewportWidth * slideWidthRatio;
  const slideMargin = viewportWidth * slideMarginRatio;
  const slideOuterWidth = slideWidth + slideMargin * 2;

  useEffect(() => {
    const updateWidth = () => {
      if (viewportRef.current) {
        setViewportWidth(viewportRef.current.offsetWidth);
      }
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const canGoPrev = displayIndex > 0;
  const canGoNext = hasImages && displayIndex < images.length - 1;

  // Compute the translation to centre the current slide
  // Formula:
  // Centre point = viewportWidth / 2
  // Slide center = (displayIndex * slideOuterWidth) + (slideOuterWidth / 2)
  // transform = Centre point - Slide center
  const getTransformX = () => {
    if (!hasImages) return 0;
    return (viewportWidth / 2) - ((displayIndex * slideOuterWidth) + (slideOuterWidth / 2));
  };

  const trackStyle = {
    transform: `translateX(${getTransformX()}px)`
  };

  const handleNext = () => {
    if (canGoNext) setDisplayIndex(prev => prev + 1);
  };

  const handlePrev = () => {
    if (canGoPrev) setDisplayIndex(prev => prev - 1);
  };

  return (
    <div className="photo-gallery-container">
      {hasImages ? (
        <>
          <button
            onClick={handlePrev}
            className="photo-gallery-arrow left-arrow"
            aria-label="Previous image"
            disabled={!canGoPrev}
          >
            &lt;
          </button>

          <div className="photo-gallery-viewport" ref={viewportRef}>
            <div className="photo-gallery-track" style={trackStyle}>
              {images.map((image, index) => (
                <div
                  className="photo-gallery-slide"
                  key={index}
                  style={{
                    width: slideWidth,
                    margin: `0 ${slideMargin}px`
                  }}
                >
                  <img
                    src={image.src}
                    alt={image.alt || `Gallery image ${index + 1}`}
                    className="photo-gallery-image"
                  />
                  {image.caption && (
                    <div className="photo-gallery-caption">{image.caption}</div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={handleNext}
            className="photo-gallery-arrow right-arrow"
            aria-label="Next image"
            disabled={!canGoNext}
          >
            &gt;
          </button>
        </>
      ) : (
        <div>No images available.</div>
      )}
    </div>
  );
};

export default PhotoGallery;
