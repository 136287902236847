import React from 'react';
import TravelBlogPage from '../../components/TravelBlog/TravelBlogPage';

import KoalaImg from '../../assets/images/Travel/Brisbane/koala.jpg';
import Koala2Img from '../../assets/images/Travel/Brisbane/koala-2.jpg';
import DolphinImg from '../../assets/images/Travel/Brisbane/dolphin.jpg';
import KangarooImg from '../../assets/images/Travel/Brisbane/Kangaroo.jpg';
import TangaloomaImg from '../../assets/images/Travel/Brisbane/Tangalooma.jpg';

import JudyHomePartyImg from '../../assets/images/Travel/Brisbane/JudyHomeParty.jpg';
import BirdImg from '../../assets/images/Travel/Brisbane/bird.jpg';
import TangaloomaViewImg from '../../assets/images/Travel/Brisbane/TangaloomaView.jpg';
import TangaloomaBoarding2Img from '../../assets/images/Travel/Brisbane/TangaloomaBoarding2.jpg';


const accommodations = [
  {
    // title: "Seaside Resort",
    topic: "Holiday Inn Express Brisbane Central",
    description: "算是住過的Holiday Inn 中品質比較普的，不過也還行。從火車站走過去爬了一個大上坡，累成狗。",
    bulletPoints: [
      "地址: 168 Wharf St, Spring Hill QLD 4000",
      "推薦指數: 3.5/5"
    ],
    images: [
    ]
  }
];


const GreatOceanRoadTravelBlog = () => {
  const greatOceanRoadTripData = {
    title: "Brisbane三日遊 - Tangalooma與海豚",
    subtitle: "這個小島可以餵食野生海豚欸",
    accommodation: accommodations, // Use a colon instead of an equals sign
    
    featuredImage: {
      src: DolphinImg,
      alt: "Dolphin",
      caption: "野生海豚與保育員建立的信任使我們有機會近距離餵食"
    },
    
    journeyIntro: "有幾位朋友與家人在Brisbane生活，於是在雪梨行程後，我們也踏上了前往Brisbane的旅程。這次我們安排了三天，包含了第一天拜訪親友、第二天前往小島餵海豚以及第三天搭船去昆士蘭大學行程。",
    
    // Updated to object format for the PhotoGallery component
    galleryImages: [
      
      {
        src: KoalaImg,
        alt: "Lone Pine Koala Sanctuary",
        caption: "Lone Pine Koala Sanctuary的無尾熊"
      },
      {
        src: Koala2Img,
        alt: "Lone Pine Koala Sanctuary 2",
        caption: "睡成一坨無尾熊"
      },
      {
        src: KangarooImg,
        alt: "Kangaroo",
        caption: "Lone Pine: 餵袋鼠"
      },
      {
        src: TangaloomaImg,
        alt: "WorkingDogShow",
        caption: "他們安排的沙灘車park很不錯"
      }
    ],
    
    travelPlan: [
      {
        day: 1,
        activities: [
          {text: '從雪梨出發，上午抵達布里斯本。我們搭乘了火車前往飯店，居然給我中途停，叫我們搭替代公車。事後回想起來，根本就應該叫Uber就好。' },
          {text: '第一天白天的行程是Lone Pine無尾熊保育區，這裡的無尾熊非常過動，難得看到他們跳來跳去的樣子。這裡也有餵食袋鼠的園區。出乎意料之外，餵袋鼠反而成為了行程重點。' },
          {text: '當天晚上我們跑去了Judy阿姨家，吃了豐盛的晚餐，參觀了Queenslander房屋的樣式。復古而奢華，隔壁是前總理的家(好狠)。' }
        ]
      },
      {
        day: 2,
        activities: [
          {text: '第二天一早驅車前往上船地點，集合前往在摩頓島上的Tangalooma渡假村。'},
          {text: '白天除了島嶼風光欣賞，我們也划了獨木舟、開著沙灘車環島，最後，來到最精彩的部分: 餵食野生海豚。由於長時間與保育員維持得信任關係，這群野生的海豚願意在有保育員在地情形下，從遊客手中獲取食物。這部分真的超酷!'}
        ]
      },
      {
        day: 3,
        activities: [
          {text: '第三天的行程相對簡單，搭船去UQ，體驗了一下城市內的河運交通。然後中午去吃了頓好吃的義大利菜，就準備回家啦!'},
        ]
      }
    ],
    
    // You can also replace highlights with another PhotoGallery if desired.
    // For consistency, we'll convert highlightImages to the new format as well.
    highlights: {
      text: "整燙的高光時刻絕對是海豚從手中搶走食物的瞬間，但其實這趟充滿著在外地難得能與朋友相聚的機會，甚至是與父母當年的好友一起共進晚餐。",
      
    },
    highlightsGalleryImages: [
      {
        src: TangaloomaBoarding2Img,
        alt: "PenguinParade",
        caption: "下船啦"
      },
      {
        src: JudyHomePartyImg,
        alt: "MotelDog",
        caption: "去Judy阿姨家吃晚餐"
      },
      {
        src: BirdImg,
        alt: "PenguinParade",
        caption: "一直看我們吃飯的鳥"
      },
      {
        src: TangaloomaViewImg,
        alt: "PenguinParade",
        caption: "碼頭風景，水很清澈"
      }
      
    ],


    
    finalThoughts: "可能因為是夏天，布里斯本的天氣跟亞洲雅熱帶地區好像，又濕又熱。與雪梨、墨爾本相比，布里斯本開發程度較低，成市中心規模較小。可以感受到更多的純樸風光，更少的城市混亂氣息。給我選擇的話，或許我還是更傾向於前者的繁華，畢竟在澳洲，本來就已經夠接近自然與綠地。以旅遊來講，Morton Island的Tangalooma規劃的還是不賴，可以來。",
    
    author: {
      name: "Yun Chu",
      date: "2024/12/8"
      // image: "/api/placeholder/80/80",
      // bio: "Embedded Systems Engineer and adventure enthusiast. Always seeking new experiences and sharing stories along the way."
    }
  };

  return <TravelBlogPage {...greatOceanRoadTripData} />;
};

export default GreatOceanRoadTravelBlog;
