import React from 'react';
import TravelBlogPage from '../../components/TravelBlog/TravelBlogPage';

import PenguinParadeImg from '../../assets/images/Travel/Phillip Island/PenguinParade.jpg';
import PenguinParade2Img from '../../assets/images/Travel/Phillip Island/PenguinParade-2.jpg';
import PenguinParade3Img from '../../assets/images/Travel/Phillip Island/PenguinParade-3.jpg';
import PenguinCentreImg from '../../assets/images/Travel/Phillip Island/PenguinCentre.jpg';
import PenguinCentreSelfieImg from '../../assets/images/Travel/Phillip Island/PenguinCentreSelfie.jpg';
import RamadaImg from '../../assets/images/Travel/Phillip Island/Ramada.jpg';
import WorkingDogShowImg from '../../assets/images/Travel/Phillip Island/WokingDogShow.jpg';

const accommodations = [
  {
    // title: "Seaside Resort",
    topic: "Ramada Resort Phillip Island",
    description: "家庭式小木屋，價格真的便宜。園區內沒有什麼設施，也沒有吃的，所以要記得自己去買。去菲利普島通常都是開車去，所以開車出去五分鐘就可以買到吃的，應該不是問題。",
    bulletPoints: [
      "地址: Phillip Island, 2128 Phillip Island Rd, Cowes VIC 3922",
      "推薦指數: 5/5"
    ],
    images: [
      { src: RamadaImg, alt: "Ramada" }
    ]
  }
];


const PhillipIslandTravelBlog = () => {
  const phillipIslandTripData = {
    title: "Phillip Island - 小企鵝歸巢",
    subtitle: "墨爾本最著名的景點之一: Panguin Parade!",
    accommodation: accommodations, // Use a colon instead of an equals sign
    
    featuredImage: {
      src: PenguinParadeImg,
      alt: "Penguin Parade",
      caption: "Panguin Parade"
    },
    
    journeyIntro: "小企鵝歸巢是墨爾本最著名的景點之一，這也是我第二次來訪。第一次是一日遊，真的太累，無法欣賞小企鵝的可愛。這次選擇住一晚，可以慢慢觀賞不用擔心回程時間。",
    
    // Updated to object format for the PhotoGallery component
    galleryImages: [
      
      {
        src: PenguinCentreImg,
        alt: "Willies Creek",
        caption: "買紀念品啦!"
      },
      {
        src: PenguinCentreSelfieImg,
        alt: "PenguinCentreSelfie",
        caption: "毛衣企鵝"
      },
      {
        src: WorkingDogShowImg,
        alt: "WorkingDogShow",
        caption: "Churchill Island牧羊犬秀"
      }
    ],
    
    travelPlan: [
      {
        day: 1,
        activities: [
          {text: '一早去領車出門，租的是Thrifty的七人座大車。從墨爾本驅車前往菲立浦島。' },
          {text: '途經一個農場Caldermeade Farm & Cafe，吃了個早餐。意外的發現這個農場生產的羊奶在Coles跟Woolworth很常見。' },
          {text: '在菲立普島旁，有一個Churchill Island農場可以觀賞一些節目(牧羊犬秀、馬鞭秀、剪羊毛等等)。很悠閒的殺了兩個小時後，我們才前往住宿地。' },
          {text: '開車前往看小企鵝的專門地點，需要提早到搶位子(就算是買了好的票種也要)。我們訂的是Premeium票，每人多花AUD80，才可以近距離觀賞。自己是覺得錢花得很值得。' },
          {text: '晚餐很簡單，就是去超市買烤雞、麵包、沙拉到我們的小屋吃。' }   
        ]
      },
      {
        day: 2,
        activities: [
          {text: '第二天早上在菲立普島上晃了一下，吃了早餐後就驅車前往Moonlit WildLife Sanctuary。感覺是一個規劃的很棒的園區，而且有很多Encoutner活動可以額外預約。我們選擇了Kookaburra的近距離接觸，餵食澳洲本土大型笑翠鳥。細節我放在另一篇寫。'},
          {text: '晚上回到墨爾本市區，吃了一家很好吃印度菜Three Brothers。真的很好吃。'}
        ]
      }
    ],
    
    // You can also replace highlights with another PhotoGallery if desired.
    // For consistency, we'll convert highlightImages to the new format as well.
    highlights: {
      text: "看到好多小企鵝衝上岸。有一顆石頭絆倒了好多隻小企鵝，好多個白白的肚子前仆後繼超好笑。",
      
    },
    highlightsGalleryImages: [
      {
        src: PenguinParade2Img,
        alt: "PenguinParade",
        caption: "小企鵝回家啦"
      },
      {
        src: PenguinParade3Img,
        alt: "PenguinParade",
        caption: "小企鵝歸巢"
      },
    ],


    
    finalThoughts: "小企鵝島要去就去兩天，真的比較好玩。然後冬天去也比夏天去好一點，畢竟天黑的早，不用太晚回家(小企鵝不會看手錶)。",
    
    author: {
      name: "Yun Chu",
      date: "2024/12/8"
      // image: "/api/placeholder/80/80",
      // bio: "Embedded Systems Engineer and adventure enthusiast. Always seeking new experiences and sharing stories along the way."
    }
  };

  return <TravelBlogPage {...phillipIslandTripData} />;
};

export default PhillipIslandTravelBlog;
