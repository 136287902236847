import React from 'react';
import TravelBlogPage from '../../components/TravelBlog/TravelBlogPage';

import MotelDogImg from '../../assets/images/Travel/Great Ocean Road/MotelDog.jpg';
import TwelveApostlesImg from '../../assets/images/Travel/Great Ocean Road/twelve apostles.jpg';
import BreakfastImg from '../../assets/images/Travel/Great Ocean Road/Breakfast.jpg';
import KoalaImg from '../../assets/images/Travel/Great Ocean Road/Koala.jpg';
import PortCampbellImg from '../../assets/images/Travel/Great Ocean Road/Port Campbell.jpg';
import PortCampbell2Img from '../../assets/images/Travel/Great Ocean Road/PortCampbell-2.jpg';
import RedForestImg from '../../assets/images/Travel/Great Ocean Road/RedForest.jpg';

import WineryInsideImg from '../../assets/images/Travel/Yarra Valley/WineryInside.jpg';
import WineryImg from '../../assets/images/Travel/Yarra Valley/Winery.jpg';
import Winery2Img from '../../assets/images/Travel/Yarra Valley/Winery2.jpg';
import VineYardImg from '../../assets/images/Travel/Yarra Valley/VineYard.jpg';
import PieInTheSkyImg from '../../assets/images/Travel/Yarra Valley/PieInTheSky.jpg';
import PieInTheSkyQueue2Img from '../../assets/images/Travel/Yarra Valley/PieInTheSkyQueue2.jpg';
import GroupPicImg from '../../assets/images/Travel/Yarra Valley/GroupPic.jpg';
import WineryMenuImg from '../../assets/images/Travel/Yarra Valley/WineryMenu.jpg';

const accommodations = [
];


const GreatOceanRoadTravelBlog = () => {
  const greatOceanRoadTripData = {
    title: "Yarra Valley 酒莊一日遊",
    subtitle: "墨爾本大學台灣同學會(MUTSA)舉辦的郊遊活動",
    accommodation: accommodations,
    
    featuredImage: {
      src: WineryInsideImg,
      alt: "WineryInsideImg",
      caption: "酒莊內的商店，品酒的照片因為太專心喝酒沒拍"
    },
    
    journeyIntro: "墨爾本大學台灣同學會舉辦這次的酒莊一日遊，由導遊Yoyo帶隊。行程包含了巧克力工廠、酒莊品酒、野餐、花卉節以及澳洲派品嘗等活動。我們藉機認識了好多朋友，很開心在這裡遇到一堆台灣同學。",
    
    // Updated to object format for the PhotoGallery component
    galleryImages: [
      
      {
        src: PieInTheSkyQueue2Img,
        alt: "PieInTheSkyQueue2Img",
        caption: "非常好吃的得獎澳洲派(Pie In The Sky)，自此愛上蘑菇雞肉派。"
      },
      {
        src: VineYardImg,
        alt: "VineYardImg",
        caption: "酒莊的葡萄園"
      },
      {
        src: WineryMenuImg,
        alt: "WineryMenuImg",
        caption: "品酒Menu"
      }
    ],
    
    travelPlan: [
      {
        day: 1,
        activities: [
          {text: '在墨爾本大學的小門集合後，坐著大巴士前往第一景點:巧克力工廠。' },
          {text: '巧克力工廠停留半小時後，我們就前往酒莊。' },
          {text: '酒莊內品酒是本次旅遊重點，講解的老師細細介紹各種酒類的特色。' },
          {text: '接下來就是野餐活動，可惜此時開始下起小雨。' },
          {text: '下午的花卉節園區也是相對冷清，就是大家一起散散步以及躲雨。' },
          {text: '最後到了歐洲小鎮購買了得獎澳洲派Pie in the sky，然後就回家啦' },
          {text: '當天晚上我們與朋友在家裡烤著派，喝著酒，享受課業尚未炸開的清閒。' }
        ]
      }
    ],
    
    // You can also replace highlights with another PhotoGallery if desired.
    // For consistency, we'll convert highlightImages to the new format as well.
    highlights: {
      text: "品酒體驗挺不錯的，不過我更喜歡澳洲派與歐洲小鎮。那個派真的好好吃。",
      
    },
    highlightsGalleryImages: [
      {
        src: WineryImg,
        alt: "WineryImg",
        caption: "酒莊"
      },
      {
        src: Winery2Img,
        alt: "Winery2Img",
        caption: "酒莊的另一個角度"
      },
      {
        src: PieInTheSkyImg,
        alt: "PieInTheSkyImg",
        caption: "Pie In The Sky 滿好吃的"
      },
      {
        src: GroupPicImg,
        alt: "GroupPicImg",
        caption: "好朋友合照(雖然我不在內)"
      }
    ],


    
    finalThoughts: "很開心有報名參加這次活動，認識了不少朋友，也認識到蘑菇雞肉派的美好。下次如果有機會，應該在那個歐洲小鎮待久一點。",
    
    author: {
      name: "Yun Chu",
      date: "2024/12/8"
      // image: "/api/placeholder/80/80",
      // bio: "Embedded Systems Engineer and adventure enthusiast. Always seeking new experiences and sharing stories along the way."
    }
  };

  return <TravelBlogPage {...greatOceanRoadTripData} />;
};

export default GreatOceanRoadTravelBlog;
