// src/components/Header/Header.js

import React from 'react';
import './Header.css';

const Header = ({ title, subtitle }) => {
  return (
    <header className="page-header">
      <h1>{title}</h1>
      <h4>{subtitle}</h4>
    </header>
  );
};

export default Header;

