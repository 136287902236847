import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBook, 
  faDumbbell, 
  faGlobeAmericas, 
  faCamera, 
  faHandshake 
} from '@fortawesome/free-solid-svg-icons';

import './Life.css';
import Header from '../../components/Header/Header';
import ViolinImg from '../../assets/images/Life/Violin/violin.jpg';
import CookingImg from '../../assets/images/Life/cooking.jpg';
import JudoImg from '../../assets/images/Life/Judo.jpg';
import BookImg from '../../assets/images/Life/book.jpg';
import NanImg from '../../assets/images/Life/Dogs/Nana-02.jpg';

// Reusable Life Card Component
const LifeCard = ({ imageSrc, icon, title, description, imageType = 'icon' }) => {
  return (
    <div className="life-card">
      <div className="life-image">
        {imageType === 'img' ? (
          <img src={imageSrc} alt={title} />
        ) : (
          <FontAwesomeIcon icon={icon} />
        )}
      </div>
      <div className="life-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

// Main Life Journey Component
const LifeJourney = () => {
  return (

    <div className="page-container">
        <Header 
            title="My Life Journey" 
            subtitle="Exploring Experiences, Connections, and Growth" 
        />
      {/* Interests Section */}
      <section className="life-section">
        <h2>Not Just About Engineering</h2>
        <div className="life-grid">
          <LifeCard 
            imageType="img"
            imageSrc={CookingImg}
            title="Cooking"
            description="Preparing a good meal is a big satisfaction for me, especially when people love what I make!  "
          />
          
          <LifeCard 
            imageType="img"
            imageSrc={BookImg}
            title="Reading"
            description="For me, reading fuels my imagination like nothing else. It even makes travel more magical—when I've read about a culture, it feels like I'm meeting an old friend when I visit."
          />
          
          <LifeCard 
            imageType="img"
            imageSrc = {ViolinImg}
            title="Violin"
            description="Classical music has always been a significant part of my life. From the age of seven through university, I learned to overcome frustration and steady my mind through daily practice. It has given me a way to find peace and express my emotions."
          />
          
          <LifeCard 
            
            imageType="img"
            imageSrc = {JudoImg}
            title="Judo"
            description="Practicing Judo has taught me discipline and perseverance. It challenges me physically and mentally, helping me grow in many aspects of life."
          />
        </div>
      </section>

      <section className="life-section">
        <h2>Life with Dogs</h2>
        <div className="life-grid">
          <LifeCard 
            imageType="img"
            imageSrc = {NanImg}
            title="Nana!"
            description="Our Nana is a super sweet girl who loves cuddling and jogging with the family. Whenever I have time and the wetaher's nice, I will take her to the mountain nearby and run to the mountain top!"
          />
          
   
        </div>
      </section>

      {/* Quote Section */}
      <div className="quote-section">
        <blockquote>
          "Life is not about waiting for the storm to pass, it's about learning to dance in the rain."
        </blockquote>
      </div>
    </div>
  );
};

export default LifeJourney;

