import React from 'react';
import TravelBlogPage from '../../components/TravelBlog/TravelBlogPage';

import RemarkableMountainTopImg from '../../assets/images/Travel/New Zealand/remarkable-mountain-top.jpg';
import BluffOysterImg from '../../assets/images/Travel/New Zealand/BluffOyster.jpg';
import FurgsBurgerQueueImg from '../../assets/images/Travel/New Zealand/FurgsBurgerQueue.jpg';
import FurgsBurgerIsSoGoodImg from '../../assets/images/Travel/New Zealand/FurgsBurgerIsSoGood.jpg';
import FurgsBurgerMeImg from '../../assets/images/Travel/New Zealand/FurgsBurgerMe.jpg';
import GotMyCertificateImg from '../../assets/images/Travel/New Zealand/GotMyCertificate.jpg';
import GroupPicImg from '../../assets/images/Travel/New Zealand/GroupPic.jpg';
import GroupPic2Img from '../../assets/images/Travel/New Zealand/GroupPic2.jpg';
import JustWowImg from '../../assets/images/Travel/New Zealand/JustWow.jpg';
import LakeViewImg from '../../assets/images/Travel/New Zealand/LakeView.jpg';
import YunTingImg from '../../assets/images/Travel/New Zealand/YunTing.jpg';
import HotelImg from '../../assets/images/Travel/New Zealand/Hotel.jpg';
import HotelDinnerHotelImg from '../../assets/images/Travel/New Zealand/HotelDinner.jpg';
import GlovesImg from '../../assets/images/Travel/New Zealand/Gloves.jpg';


const accommodations = [
  {
    // title: "Seaside Resort",
    topic: "Holiday Inn Express & Suites Queenstown, an IHG Hotel ",
    description: "非常舒適的一間飯店，櫃台人員超級友善，食物也很不錯。非常非常滿意。包含了健身房、桑拿房等等，也有專門晾乾滑雪用具的地方。房間尺寸適中，基本上與過往滑雪住過的地方差不多。",
    bulletPoints: [
      "地址: Cnr Stanley And Sydney Street, Queenstown 9300紐西蘭",
      "推薦指數: 5/5",
      "價格:住了11晚/NZD 3,163"
    ],
    images: [
      { src: HotelImg, alt: "HotelImg" },
      { src: HotelDinnerHotelImg, alt: "HotelDinnerHotelImg" }
    ]
  }
];


const TravelBlog = () => {
  const TripData = {
    title: "Queenstown & NZSIA 滑雪之旅",
    subtitle: "這裡是上帝贈與人類最美的土地之一",
    accommodation: accommodations, // Use a colon instead of an equals sign
    
    featuredImage: {
      src: RemarkableMountainTopImg,
      alt: "RemarkableMountainTopImg",
      caption: "Remarkables雪場山上的風景"
    },
    
    journeyIntro: "距離2019年拿到NZSIA一級教練證已經一段時間了，趁著假期，殺向紐西蘭挑戰二級，也順便帶著沒滑過的一起體驗一下。第二級的時間較長，再加上前面的暖身時間，總共規劃在皇后鎮待12天。",
    
    // Updated to object format for the PhotoGallery component
    galleryImages: [
      
      {
        src: YunTingImg,
        alt: "YunTingImg",
        caption: "離開前，終於挑戰了一次搭纜車到山頂"
      },
      {
        src: FurgsBurgerIsSoGoodImg,
        alt: "FurgsBurgerIsSoGoodImg",
        caption: "Furg's Burger不愧是CNN譽為世界上最好吃的漢堡，超好吃"
      },
      {
        src: LakeViewImg,
        alt: "LakeViewImg",
        caption: "皇后鎮上的湖景"
      },
      {
        src: BluffOysterImg,
        alt: "BluffOysterImg",
        caption: "紐西蘭特有的Bluff Oyster，味道甜美，口感扎實"
      }
    ],
    
    travelPlan: [
      {
        day: 1,
        activities: [
          {text: '抵達的那一剎那，那眼前景色是難以想像的壯麗。從來沒有見過山在如此近的距離拔地而起，好像只應該出現在電影中的磅礡情境。第一日我們抵達飯店，放好東西到街上。前兩天因為買票不夠早，沒有機會上山，所以在上逛逛，也順便購買更新一些陳年裝備。' }
        ]
      },
      {
        day: 2,
        activities: [
          {text: '有點忘了，再找一下資料。先睡覺之後補。'}
        ]
      }
    ],
    
    // You can also replace highlights with another PhotoGallery if desired.
    // For consistency, we'll convert highlightImages to the new format as well.
    highlights: {
      text: "滑雪的部分總是在離開後令人回味無窮，在那裏當下我對於Furg's Burger更為熱愛。真的超好吃。還有紐西蘭才吃的到的Bluff Oyster。據說我們吃的不是特別新鮮的，畢竟Queenstown離產地太遠。下次再造訪紐西蘭我肯定殺去吃最新鮮的。Furg's Burger的藍起司派也超讚，可惜太香我來不及拍照留念。至於NZSIA的部分嘛，我考過啦!",
      
    },
    highlightsGalleryImages: [
      {
        src: FurgsBurgerQueueImg,
        alt: "FurgsBurgerQueueImg",
        caption: "Furg's Burger的排隊人潮很驚人，但也真的很好吃。旁邊有Bakery跟Bar可以殺些時間。值得等!"
      },
      {
        src: FurgsBurgerMeImg,
        alt: "FurgsBurgerMeImg",
        caption: "等候我的鹿肉漢堡(還是原味好吃)"
      },
      {
        src: GroupPicImg,
        alt: "GroupPicImg",
        caption: "NZSIA Colin組合照們"
      },
      {
        src: GroupPic2Img,
        alt: "GroupPic2Img",
        caption: "NZSIA完結合照"
      },
      {
        src: GotMyCertificateImg,
        alt: "GotMyCertificateImg",
        caption: "拿到二級證書啦!"
      },
      {
        src: JustWowImg,
        alt: "JustWowImg",
        caption: "下山途中看到雲海，感覺這裡隨手一拍都超美"
      },
      {
        src: GlovesImg,
        alt: "GlovesImg",
        caption: "飯店內提供烘手套的器具，看看我這老手套的殘破不堪"
      },
      
      
      

    ],


    
    finalThoughts: "皇后鎮真的是一個很特別的地方，這趟旅行也帶給我難以抹滅的回憶。從一降落的群山環繞，到山頂上的皚皚白雪。就算已經滑雪超過15年，也還是為之震撼。Remarkables不算是特別優秀的雪場，我更傾向未來去日本滑雪。但是，紐西蘭的風景、空氣、水，是最純粹最乾淨的大自然餽贈。令人永生難忘。",
    
    author: {
      name: "Yun Chu",
      date: "2024/12/7"
      // image: "/api/placeholder/80/80",
      // bio: "Embedded Systems Engineer and adventure enthusiast. Always seeking new experiences and sharing stories along the way."
    }
  };

  return <TravelBlogPage {...TripData} />;
};

export default TravelBlog;
