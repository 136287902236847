// src/pages/About/About.js

import React from 'react';
import Header from '../../../components/Header/Header';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

import './Lenexa.css'
import LenexaInternshipDebugImg from '../../../assets/images/Lenexa/lenexa-internship.jpg';
import LenexaDataboxDebugImg from '../../../assets/images/Lenexa/lenexa-databox.jpg';
import LenexaShippingDebugImg from '../../../assets/images/Lenexa/lenexa-shipping.jpg';
import LenexaFriendsImg from '../../../assets/images/Lenexa/lenexa-friends.jpg';
import LenexaSunriseHotelImg from '../../../assets/images/Lenexa/lenexa-friends-hotel-sunrise.jpg';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const LenexaPage = () => {
  // Data for the charts
  const mandarinData = {
    labels: ['Proficient', 'Remaining'],
    datasets: [
      {
        data: [95, 5],
        backgroundColor: ['#b88e72', '#e0e0e0'],
        borderWidth: 0,
      },
    ],
  };

  const englishData = {
    labels: ['Proficient', 'Remaining'],
    datasets: [
      {
        data: [88, 12],
        backgroundColor: ['#3498db', '#e0e0e0'],
        borderWidth: 0,
      },
    ],
  };

  const taiwaneseData = {
    labels: ['Proficient', 'Remaining'],
    datasets: [
      {
        data: [30, 70],
        backgroundColor: ['#2c3e50', '#e0e0e0'],
        borderWidth: 0,
      },
    ],
  };

  const chartOptions = {
    cutout: '70%',
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="app-container">
      {/* Header */}
      <Header title="Journey @ Lenexa Medical" subtitle="From Intern to Professional" />

        <div class="journey-section">
            <div class="journey-story">
                <img src={LenexaInternshipDebugImg} alt="Lenexa Medical Workspace" class="story-image"/>

                <div class="story-text">
                    <h2>The First Steps</h2>
                    <p>My journey began in November 2023 as an Engineering Intern at Lenexa Medical. What started as a mandatory academic requirement quickly transformed into a profound professional experience that would reshape my understanding of medical technology and embedded systems.</p>
                    <p>From manufacturing, sensor, to firmware, we were given opportunities to operate and debug many aspects of the cutting-edge pressure-injury prevention system. The hands-on expereince not only gave me the profound understanding of how a product was made, but also provided invaluable insights in my future software development and SOP construction.
                    </p>
                    <div class="skills-gained">
                        <span class="skill-tag">Internship</span>
                        <span class="skill-tag">Healthcare Technology</span>
                        <span class="skill-tag">Learning Adaptability</span>
                    </div>
                </div>
            </div>

            <div class="journey-story">
                <img src={LenexaDataboxDebugImg} alt="Lenexa Medical Workspace" class="story-image"/>
                <div class="story-text">
                    <h2>Solving Complex Challenges</h2>
                    <p>Soon after the end of the summer internship, I continued with Lenexa and started to serve as an Embedded Systems Engineer, which aligned the most with my passion and strengh. There were several issues on data acquisition devices, including the data integrity and system stability. Problem solving and agily lerning and trying new possible solution became critical to the customer's satisfaction. As an startup, we constantly grow, expand, improve our service and devices, and that requires extreme agility and adaptability to both achieve fast development and maintain system stable.</p>
                    
                    <div class="skills-gained">
                        <span class="skill-tag">Firmware Development</span>
                        <span class="skill-tag">Sensor Integration</span>
                        <span class="skill-tag">Performance Optimization</span>
                    </div>
                </div>
            </div>

            <div class="journey-story">
                <img src={LenexaShippingDebugImg} alt="Lenexa Medical Workspace" class="story-image"/>
                <div class="story-text">
                    <h2>Innovation Through Collaboration</h2>
                    <p>Well, the picture shows only one kind of collaboration, but in this role, there are way more than just moving a box! From internal cross-functional team to external engineering partner, collaboration is the key to smoothly achieve our goal. I learned to deliver the result of my work, asking for help and clarification, responding to partner engineer's request, as well as cooperate with clients to finish the installation!</p>
                    <div class="skills-gained">
                        <span class="skill-tag">Cross-Functional Teamwork</span>
                        <span class="skill-tag">Predictive Modeling</span>
                        <span class="skill-tag">Healthcare Innovation</span>
                    </div>
                </div>
            </div>


            <div class="journey-story">
                <img src={LenexaFriendsImg} alt="Lenexa Medical Workspace" class="story-image"/>     
                               
                <div class="story-text">
                    <h2>Awesome Friends</h2>
                    <p>I made so many friends here! It's really out of my expectation that I actually met so many super-smart and friendly guys. We conqured issues togather, celebrate the success, and ofc, complaint. There were many moments that I found pressious from internship to now.</p>
                    
                    <div class="skills-gained">
                        <span class="skill-tag">Professional Development</span>
                        <span class="skill-tag">Communication</span>
                        <span class="skill-tag">Problem Solving</span>
                    </div>
                </div>
            </div>

            <div class="journey-story">
                <img src={LenexaSunriseHotelImg} alt="Lenexa Medical Workspace" class="story-image"/>                    
                <div class="story-text">
                    <h2>Fairwell Party for Our Legend: Derek Fan</h2>
                    <p>This picture is the time we said goodbye to Drek, who moved on to another amazing opportunity in Sydney! I personally want to thank him for all the guidance and inspiration. He is tuely the legend of Lenexa Medical and fueled the success of our system. I learned a lot from his work attitude, his engineering mindset, and the laser tag we played in Melbourne Central LOL. </p>
                    <div class="skills-gained">
                        <span class="skill-tag">Professional Development</span>
                        <span class="skill-tag">Communication</span>
                        <span class="skill-tag">Problem Solving</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
  );
};

export default LenexaPage;
