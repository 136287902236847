// TravelTimeline.js
import React, { useState } from 'react';
import './TravelTimeline.css';


const TimelineItem = ({ trip, isActive, onClick }) => (
  <div 
    className={`travel-timeline-item ${isActive ? 'active' : ''}`}
    onClick={onClick}
  >
    <div className="travel-timeline-dot">
      <div className="travel-timeline-line"></div>
    </div>
    <div className="travel-timeline-content">
      <div className="travel-timeline-date">{trip.date}</div>
      <div className="travel-timeline-title">{trip.title}</div>
    </div>
  </div>
);

const TravelTimeline = ({ trips, onItemClick }) => {
  const [activeItem, setActiveItem] = useState(null);

  return (
    <div className="travel-timeline-container">
      {trips.map((trip, index) => (
        <TimelineItem
          key={trip.id}
          trip={trip}
          isActive={activeItem === index}
          onClick={() => {
            setActiveItem(index);
            onItemClick(trip.id);
          }}
        />
      ))}
    </div>
  );
};

export default TravelTimeline;
