import React from 'react';
import TravelBlogPage from '../../components/TravelBlog/TravelBlogPage';

import StanleyNutImg from '../../assets/images/Travel/Tasmania/stanley-nut.jpg';
import DevilImg from '../../assets/images/Travel/Tasmania/deviel-pouch.jpg';
import QuallImg from '../../assets/images/Travel/Tasmania/quoll.jpg';
import DevilSignImg from '../../assets/images/Travel/Tasmania/Tasmania-devil-sign.jpg';

import StanleHotelImg from '../../assets/images/Travel/Tasmania/stanley-hotel.jpg';
import StanleyHotelViewImg from '../../assets/images/Travel/Tasmania/Stanley-Hotel-View.jpg';
import HotelVergeImg from '../../assets/images/Travel/Tasmania/hotel-verge-launceston.jpg';
import HotelVergeLobbyImg from '../../assets/images/Travel/Tasmania/hotel-verge-launceston-lobby.jpg';
import GlampingImg from '../../assets/images/Travel/Tasmania/glamping.jpg';
import GlampingViewImg from '../../assets/images/Travel/Tasmania/glamping-view.jpg';
import WombatImg from '../../assets/images/Travel/Tasmania/wombat.jpg';
import Leas65Img from '../../assets/images/Travel/Tasmania/lease-65.jpg';

const accommodations = [
  {
    // title: "Seaside Resort",
    topic: "Day 1 - Day 2: Stanley Village Waterfront",
    description: "為了不受管制的觀察小企鵝上岸，我們打聽到了這間小企鵝出沒的海邊小屋。晚上屋外只出現了兩隻小傢伙努力的穿過石頭堆，不像聽說的那樣會在街上走。Staneley小鎮非常寧靜，晚餐選項也頗少。至少至一間房間長相很美，海洋與島的美麗透過玻璃窗一覽無遺。算是推薦大家來住這間。",
    bulletPoints: [
      "地址: 15 Wharf Rd, Stanley TAS 7331",
      "推薦指數: 4.5/5"
    ],
    images: [
      { src: StanleHotelImg, alt: "Resort exterior" },
      { src: StanleyHotelViewImg, alt: "Room with ocean view" }
    ]
  },
  {
    // title: "Mountain Lodge",
    topic: "Day 2 - Day 3: Hotel Verge Launceston",
    description: "Launceston市區並不繁華，我們挑了一個看起來嶄新的小飯店住了一晚。大廳與房間看起來嶄新且豪華，不過個人認為特點不多。還行。",
    bulletPoints: [
      "地址: 50 Tamar Street, Launceston",
      "推薦指數: 4/5"
    ],
    images: [
      { src: HotelVergeImg, alt: "Lodge exterior" },
      { src: HotelVergeLobbyImg, alt: "Interior of a lodge room" }
    ]
  },
  {
    // title: "Mountain Lodge",
    topic: "Day 3 - Day 4: Wingtons Glamping",
    description: "這裡是一個與世隔絕的河岸。沒有餐廳，沒有車流，甚至沒有任何接待的人員。所謂的入住，其實就是扛著行李，沿著稍微規劃的小徑，走下山坡，走到抵達帳篷。豪華的帳篷帶給我們一種蒙古貴族住所的印象，主帳篷外設有一個小小的衛浴帳篷，算是一種原始與現代混和的體驗。\n\n晚餐是BBQ。我們烤著事先購買好的豬肋排與麵包，搭配著沙拉。徐徐微風，確實靜謐且舒適。隨著太陽降下，逐漸淡去的明亮與我們營火的火光似乎抹去了小動物們的膽怯，有一隻Wallaby悄悄的靠近我們的營地，注視著我們，再悄悄離去。而這一切都是如此的平靜、美好，卻也是下一個篇章的前奏：不依不撓的負鼠(Brushtail Possum)。",
    
    bulletPoints: [
      "地址: 41 Sunset Blvd, Clarence Point TAS 7270",
      "挑戰了一晚住帳棚，豪華glamping。",
      "推薦指數: 4/5"

    ],
    images: [
      { src: GlampingImg, alt: "Lodge exterior" },
      { src: GlampingViewImg, alt: "Interior of a lodge room" }
    ]
  }
];


const TasmaniaTravelBlog = () => {
  const tasmaniaTripData = {
    title: "Tasmania之旅－曠野與絨毛小傢伙",
    subtitle: "Tasmania devils真的太可愛了!",
    accommodation: accommodations, // Use a colon instead of an equals sign
    
    featuredImage: {
      src: StanleyNutImg,
      alt: "Stanley Nut",
      caption: "The iconic Stanley Nut, a distinctive geological formation in Tasmania"
    },
    
    journeyIntro: "是一場說走就走的旅行(甚至是暑期實習的時候在辦公室訂的機票)。塔斯馬尼亞，好似我們對紐西蘭皇后鎮的印象，是地球上最好的幾個秘境。而且這裡是超級可愛的Tasmania Devil的家園，甚至有地方可以摸!",
    
    // Updated to object format for the PhotoGallery component
    galleryImages: [
      {
        src: DevilImg,
        alt: "Tasmanian Devil",
        caption: "幼年Tasmanian Devil與展示它育兒袋的保育員，小devil很愛這位保育員，所以願意乖乖給她這樣摟著"
      },
      {
        src: QuallImg,
        alt: "Eastern Quoll",
        caption: "寶寶Quoll(東袋鼬)是我們這趟遇到最神奇的靈動小動物之一"
      },
      {
        src: DevilSignImg,
        alt: "Tasmania Devil Sign",
        caption: "減速慢行，不要路殺!"
      }
    ],
    
    travelPlan: [
      {
        day: 1,
        activities: [
          { icon: 'plane-arrival', text: '抵達Launceston機場(北邊的機場)' },
          { icon: 'car', text: '取車Bargain，特別有趣的一點是這家的取車點在機場對面，但是必須搭接駁車過去，走路是走不到的。(至於為什麼是它家，因為便宜阿哈哈哈)' },
          { icon: 'car', text: '前往41 South Tasmania and Georgie\'s Cafe。有一隻很友善的牧羊犬，以及有名的鮭魚養殖參觀行程。這裡沒有訊號，也是一個很好強迫你靜下來的地方。' },
          { icon: 'car', text: '第一天的另一個重中之重，就是前往Trowunna看Tasmania Devil以及Wombat。印象中，接線阿姨不太友善(應該就是門口那個)，但是解說員超級讚。我們也是在這裡才發現澳洲的Quoll(東袋鼬)超級可愛!' },
          { icon: 'car', text: '入住Stanley Village Waterfront (因為聽說這裡可以看到野生小企鵝走在街上)。實際上呢，我們只看到兩隻小企鵝很艱難的從岸邊石頭堆慢慢往上移動。' },
          { icon: 'car', text: '晚餐吃Stanley Hotel Bistro' }
        ]
      },
      {
        day: 2,
        activities: [
          {icon: 'plane-arrival', text: '前往旁邊的海中巨岩搭乘The Nut Chair Lift (return tickets: AUD19 pp)'},
          {icon: 'plane-arrival', text: 'Jimmy Lane Memorial Lookout與大相框合照。'},
          {icon: 'plane-arrival', text:'前往搖籃山(Cradle Mountain)的Devils@Cradle，路程相對遙遠。許多人前往搖籃山的目的是步行登山，不過我們直衝向超可愛的devils。'},
          {icon: 'plane-arrival', text:'4-5pm是我們預約的Joey Encounter私人導覽，價錢非常合理，大推大推。'},
          {icon: 'plane-arrival', text:'摸完小動物們後，驅車回到Launceston市中心'},
          {icon: 'plane-arrival', text:'入住Hotel Verge Launcesto'}
        ]
      },
      {
        day: 3,
        activities: [
          {icon: 'plane-arrival', text: '前往旁邊的海中巨岩搭乘The Nut Chair Lift (return tickets: AUD19 pp)'},
          {icon: 'plane-arrival', text: 'Jimmy Lane Memorial Lookout與大相框合照。'},
          {icon: 'plane-arrival', text:'前往搖籃山(Cradle Mountain)的Devils@Cradle，路程相對遙遠。許多人前往搖籃山的目的是步行登山，不過我們直衝向超可愛的devils。'},
          {icon: 'plane-arrival', text:'4-5pm是我們預約的Joey Encounter私人導覽，價錢非常合理，大推大推。'},
          {icon: 'plane-arrival', text:'摸完小動物們後，驅車回到Launceston市中心'},
          {icon: 'plane-arrival', text:'入住Hotel Verge Launcesto'}
        ]
      },
      {
        day: 4,
        activities: [
          {icon: 'plane-arrival', text: '前往旁邊的海中巨岩搭乘The Nut Chair Lift (return tickets: AUD19 pp)'},
          {icon: 'plane-arrival', text: 'Jimmy Lane Memorial Lookout與大相框合照。'},
          {icon: 'plane-arrival', text:'前往搖籃山(Cradle Mountain)的Devils@Cradle，路程相對遙遠。許多人前往搖籃山的目的是步行登山，不過我們直衝向超可愛的devils。'},
          {icon: 'plane-arrival', text:'4-5pm是我們預約的Joey Encounter私人導覽，價錢非常合理，大推大推。'},
          {icon: 'plane-arrival', text:'摸完小動物們後，驅車回到Launceston市中心'},
          {icon: 'plane-arrival', text:'入住Hotel Verge Launcesto'}
        ]
      }
    ],
    
    // You can also replace highlights with another PhotoGallery if desired.
    // For consistency, we'll convert highlightImages to the new format as well.
    highlights: {
      text: "這趟旅中最值得回憶的部分肯定是搖籃山上的Devils@Cradle。購買Joey Encounter行程絕對是最正確的決定，不只包含了入園的門票，還有私人導覽以及與Devil寶寶互動!可以明顯觀察到導覽員受到Devils寶寶們熱烈的愛，沒有被抱到的黑色小朋友會跟剛剛被放下來的吵架(超可愛)。Quoll(東袋鼬)也是超級可愛、超級靈動。來到塔斯馬尼亞之前，我甚至不知道有這麼可愛的小動物!",
      
    },
    highlightsGalleryImages: [
      {
        src: WombatImg,
        alt: "A Tasmanian Devil Pouch",
        caption: "袋熊的屁股真的好硬"
      },
      {
        src: Leas65Img,
        alt: "Lease 65 Oyster Farm",
        caption: "最好吃的生蠔肯定是在生蠔養殖場直接打開的!"
      }
    ],


    
    finalThoughts: "這篇心得是我的個人網頁試營運階段寫的，希望能夠帶給讀者一點幫助，也是為自己紀錄美好的回憶。未來會逐步回來補上缺失的部分，還請讀者多多包容。",
    
    author: {
      name: "Yun Chu",
      image: "/api/placeholder/80/80",
      bio: "Embedded Systems Engineer and adventure enthusiast. Always seeking new experiences and sharing stories along the way."
    }
  };

  return <TravelBlogPage {...tasmaniaTripData} />;
};

export default TasmaniaTravelBlog;
