import React from 'react';

import Header from '../../components/Header/Header';

import './Skiing.css';
import '../../styles/global.css';

// Imported image assets
import NZSIALevel1Img2 from '../../assets/images/Skiing/NZSIA-leve1-02.jpg';
import NZSIALevel1Img from '../../assets/images/Skiing/NZSIA-level1.jpg';
import NZSIALevel2Img from '../../assets/images/Skiing/NZSIA-level2.jpg';
import SkiingImg from '../../assets/images/Skiing/skiing.jpg';
import SkiFriendsImg from '../../assets/images/Skiing/ski-with-friends.jpg';

// Imported JSON data
import timelineDataJson from './skiingTimelineData.json';
import galleryDataJson from './skiingGalleryData.json';

// Skiing Timeline Component
const SkiingTimelineItem = ({ title, date, details, images }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div className="skiing-timeline-item">
      <div 
        className={`skiing-timeline-content ${isExpanded ? 'expanded' : ''}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="skiing-timeline-preview">
          <h3>{title}</h3>
          <p>{date}</p>
        </div>
        {isExpanded && (
          <div className="skiing-timeline-details">
            {images && images.map((img, index) => (
              <img 
                key={index} 
                src={img.src} 
                alt={img.alt || title} 
                className="skiing-timeline-image"
              />
            ))}
            {details && details.map((detail, index) => (
              <p key={index}>{detail}</p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

// Skiing Gallery Item Component
const SkiingGalleryItem = ({ src, alt, caption }) => {
  return (
    <div className="skiing-gallery-item">
      <img src={src} alt={alt} />
      <div className="skiing-gallery-caption">{caption}</div>
    </div>
  );
};

// Qualifications Section Component
const SkiingQualificationsSection = () => {
  return (
    <div className="ski-container">
      <h2>Skiing Qualifications & Achievements</h2>
      <div className="skiing-qualifications-content">
        <div className="qualification-item">
          <h3>NZSIA Level 2 Ski Instructor Certification</h3>
          <p>Successfully obtained the New Zealand Snowsports Instructors Association (NZSIA) Level 2 certification, demonstrating advanced skiing skills and teaching proficiency.</p>
          <ul>
            <li>Issuer: New Zealand Snowsports Instructors Association (NZSIA)</li>
            <li>Advanced technical skiing skills across various snow conditions</li>
            <li>Comprehensive understanding of ski technique and teaching methodologies</li>
            <li>Ability to instruct intermediate to advanced skiers</li>
          </ul>
        </div>
        
        {/* <div className="skiing-experience">
          <h3>Skiing Experience</h3>
          <p>Dedicated ski enthusiast with over 8 years of skiing experience across multiple international ski destinations, including:</p>
          <ul>
            <li>New Zealand ski fields (Queenstown, Wanaka)</li>
            <li>Japanese ski resorts (Hokkaido, Nagano)</li>
            <li>North American ski mountains (Whistler, Colorado)</li>
          </ul>
        </div>
        
        <div className="skiing-competitions">
          <h3>Competitions & Notable Events</h3>
          <ul>
            <li>2022 Regional Ski Instructor Challenge - 2nd Place</li>
            <li>Annual Powder Days Invitational - Participant</li>
            <li>Backcountry Safety and Avalanche Awareness Workshop - Certified</li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

// Main Skiing Page Component
const SkiingPage = () => {
  // Gallery data with local image imports
  const galleryData = [
    {
      src: NZSIALevel1Img2,
      alt: "白樺湖",
      caption: "First Black Diamond Run"
    },
    {
      src: NZSIALevel1Img,
      alt: "志賀高原Shiga Kogen",
      caption: "Powder Day at Whistler"
    },
    {
      src: NZSIALevel2Img,
      alt: "Remarkable",
      caption: "Mountain View"
    },
    {
      src: SkiingImg,
      alt: "合照",
      caption: "合照!"
    },
    {
      src: SkiFriendsImg,
      alt: "合照",
      caption: "合照!"
    },
    {
      src: SkiFriendsImg,
      alt: "合照",
      caption: "合照!"
    }
  ];

  return (
    <div className="skiing-page">
      <Header 
        title="Skiing & Grass Skiing" 
        subtitle="My Journey in the Snow World" 
      />
      
      <main className="skiing-main">
        <div className="skiing-timeline-section">
          <h2>My Skiing Adventures</h2>
          <div className="skiing-timeline">
            {timelineDataJson.timelineData.map((item, index) => (
              <SkiingTimelineItem 
                key={index}
                title={item.title}
                date={item.date}
                details={item.details}
                images={item.images}
              />
            ))}
          </div>
        </div>

        {/* New Container for Right Side Content */}
        <div className="skiing-content-section">
          <SkiingQualificationsSection />

          <div className="skiing-gallery-section">
            <h2>Ski Photo Gallery</h2>
            <div className="skiing-gallery">
              {galleryData.map((item, index) => (
                <SkiingGalleryItem 
                  key={index}
                  src={item.src}
                  alt={item.alt}
                  caption={item.caption}
                />
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SkiingPage;
