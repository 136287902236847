// src/pages/About/About.js

import React from 'react';
import './About.css';
import Header from '../../components/Header/Header';
import myPhoto from '../../assets/images/Engineering/my_photo_capstone.jpg';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const AboutPage = () => {
  // Data for the charts
  const mandarinData = {
    labels: ['Proficient', 'Remaining'],
    datasets: [
      {
        data: [95, 5],
        backgroundColor: ['#b88e72', '#e0e0e0'],
        borderWidth: 0,
      },
    ],
  };

  const englishData = {
    labels: ['Proficient', 'Remaining'],
    datasets: [
      {
        data: [88, 12],
        backgroundColor: ['#3498db', '#e0e0e0'],
        borderWidth: 0,
      },
    ],
  };

  const taiwaneseData = {
    labels: ['Proficient', 'Remaining'],
    datasets: [
      {
        data: [30, 70],
        backgroundColor: ['#2c3e50', '#e0e0e0'],
        borderWidth: 0,
      },
    ],
  };

  const chartOptions = {
    cutout: '70%',
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="app-container">
      {/* Header */}
      <Header title="About Me" subtitle="Get to know me better" />

      {/* About Section */}
      <div className="about-container">
        <div className="about-text">
          <div className="about-section">
            <h2>Who Am I?</h2>
            <p>I'm Yun Chu, an Embedded Systems Engineer at Lenexa Medical with a passion for robotics, smart home technology, and microcontroller projects. My journey in engineering has been driven by a curiosity to understand how things work and a desire to create innovative solutions.</p>
          </div>

          <div className="about-section highlight-box">
            <h2>What Drives Me</h2>
            <p>I believe in the power of technology to improve lives. Whether it's developing embedded systems for medical devices or tinkering with robotics projects in my spare time, I'm constantly seeking ways to push the boundaries of what's possible.</p>
          </div>

          <div className="about-section">
            <h2>Beyond Engineering</h2>
            <p>When I'm not immersed in technical projects, you'll find me on the ski slopes, exploring new trails, or spending time with my beloved dog. I believe in maintaining a balanced life that combines professional growth with personal passions.</p>
          </div>
        </div>

        <div className="about-image">
          <img src={myPhoto} alt="Yun Chu" />
          {/* Language Proficiency Charts */}
          <div className="dashboard">
            <div className="dashboard-item">
              <h3>Language Proficiency</h3>
              <div className="language-charts">
                {/* Mandarin Chart */}
                <div className="language-chart">
                  <Doughnut data={mandarinData} options={chartOptions} />
                  <div className="chart-percentage">95%</div>
                  <div className="chart-name">Mandarin</div>
                </div>
                {/* English Chart */}
                <div className="language-chart">
                  <Doughnut data={englishData} options={chartOptions} />
                  <div className="chart-percentage">88%</div>
                  <div className="chart-name">English</div>
                </div>
                {/* Taiwanese Chart */}
                <div className="language-chart">
                  <Doughnut data={taiwaneseData} options={chartOptions} />
                  <div className="chart-percentage">30%</div>
                  <div className="chart-name">Taiwanese</div>
                </div>
              </div>
            </div>
            {/* You can add more dashboard items here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
