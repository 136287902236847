import React from 'react';

import Header from '../../components/Header/Header';
import SocialLink from '../../components/SocialLink/SocialLink';
import './Contact.css'; // CSS Module for component-specific styles

const ContactPage = () => {
  return (
    <div className="page-container">
      <Header 
        title="Contact Me" 
        subtitle="Let's connect and start a conversation" 
      />
      
      <div className="main-content">
        
          
          <div className="contact-method">
            <h2>
              <i className="fas fa-envelope"></i> Email
            </h2>
            <p>yun.chu.au@gmail.com</p>
          </div>

          <div className="contact-method">
            <h2>
              <i className="fas fa-map-marker-alt"></i> Location
            </h2>
            <p>Melbourne, Australia</p>
          </div>

          <div className="contact-method">
            <h2>
              <i className="fas fa-clock"></i> Response Time
            </h2>
            <p>Usually within 24 hours</p>
          </div>

          <h2>Connect With Me</h2>
          <SocialLink/>
        
      </div>
    </div>
  );
};

export default ContactPage;
