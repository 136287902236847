// Travel.js
import React, { useRef } from 'react';
import Header from '../../components/Header/Header';
import TravelCard from '../../components/TravelCard/TravelCard';
import TravelTimeline from '../../components/TravelTimeline/TravelTimeline';
import './Travel.css';
import '../../styles/global.css';

import tripsData from './trips.json';



import RemarkablesTop from '../../assets/images/Travel/New Zealand/remarkable-mountain-top.jpg';
import QuokkaPerth from '../../assets/images/Travel/Perth/quokka.jpg';
import OperaHouseSydney from '../../assets/images/Travel/Sydney/opera_house.jpg';
import TwelveApostles from '../../assets/images/Travel/Great Ocean Road/twelve apostles.jpg';
import StanleyNut from '../../assets/images/Travel/Tasmania/stanley-nut.jpg';
import PenguinParade from '../../assets/images/Travel/Phillip Island/Phillip island.webp';
import Tangalooma from '../../assets/images/Travel/Brisbane/Tangalooma.jpg';
import MelbourneZoo from '../../assets/images/Travel/Melbourne Zoo/meerkats.jpg';
import MoonlitImg from '../../assets/images/Travel/Moonlit/Kookaburra.jpg';
import YarraValleyImg from '../../assets/images/Travel/YarraValley.jpg';
import defaultImage from '../../assets/images/Travel/Tasmania/Tasmania-devil-sign.jpg'; // Default Image

const imageMapping = {
  'Perth': QuokkaPerth,
  'Sydney': OperaHouseSydney,
  'Great Ocean Road': TwelveApostles,
  'Tasmania': StanleyNut,
  'Phillip Island': PenguinParade,
  'Brisbane': Tangalooma,
  'Melbourne Zoo': MelbourneZoo,
  'Moonlit':MoonlitImg,
  'Yarra Valley':YarraValleyImg
  // Add other mappings as needed
};


const Travel = () => {

  const australiaTrips = tripsData.AusTrips.map(trip => ({
    ...trip,
    imageSrc: imageMapping[trip.imageSrc] || defaultImage,
  }));

  const newZealandTrips = [
    {
      imageSrc: RemarkablesTop,
      imageAlt: "New Zealand",
      date: "20 Jun - 1 Jul, 2023",
      title: "To the Remarks!",
      excerpt: "A place with magnificent view, purest air & water, and awesome people.",
      tags: ["Remarkable", "Queenstown", "Fergburger", "NZSIA"],
      readMoreLink: "/travel/queenstown"
    }
  ];

  // Add category and unique IDs to each trip
  const allTrips = [
    ...australiaTrips.map((trip, index) => ({
      ...trip,
      category: 'Australia',
      id: `australia-${index}`,
    })),
    ...newZealandTrips.map((trip, index) => ({
      ...trip,
      category: 'New Zealand',
      id: `newzealand-${index}`,
    })),
  ];

  // Parse date strings into Date objects
  const parseDate = (dateStr) => {
    // Remove commas and extra spaces
    dateStr = dateStr.replace(',', '').trim();

    // Handle date ranges like "October 10-14 2023" or "20 Jun - 1 Jul 2023"
    const dateParts = dateStr.split(' ');

    if (dateParts.length === 3) {
      // Format: "Month Day-Day Year" or "Month Day Year"
      const month = dateParts[0];
      let dayPart = dateParts[1];
      const year = dateParts[2];

      // Handle day ranges
      let day = dayPart.split('-')[0];

      // Convert month name to month number
      const monthNumber = new Date(Date.parse(`${month} 1, 2000`)).getMonth();

      return new Date(year, monthNumber, parseInt(day));
    } else if (dateParts.length === 5) {
      // Format: "Day Month - Day Month Year"
      const day1 = dateParts[0];
      const month1 = dateParts[1];
      // const day2 = dateParts[3]; // Not used
      // const month2 = dateParts[4]; // May need adjustment
      const year = dateParts[4];

      const monthNumber = new Date(Date.parse(`${month1} 1, 2000`)).getMonth();

      return new Date(year, monthNumber, parseInt(day1));
    } else {
      // Fallback to current date
      return new Date();
    }
  };

  allTrips.forEach((trip) => {
    trip.dateObj = parseDate(trip.date);
  });

  // Sort trips by date (most recent first)
  allTrips.sort((a, b) => b.dateObj - a.dateObj);

  // Create refs for each trip
  const tripRefs = useRef({});

  // Group trips by category
  const tripsByCategory = allTrips.reduce((acc, trip) => {
    acc[trip.category] = acc[trip.category] || [];
    acc[trip.category].push(trip);
    return acc;
  }, {});

  // Handle scrolling to the corresponding TravelCard
  const handleItemClick = (tripId) => {
    const element = tripRefs.current[tripId];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <div className="travel-page">
      <Header 
        title="Travel Adventures" 
        subtitle="Documenting my journeys around the world (Sorry it's currently only in Mandarin)" 
      />
      <TravelTimeline trips={allTrips} onItemClick={handleItemClick} />
      <main className="travel-container">
        {Object.entries(tripsByCategory).map(([category, trips]) => (
          <div className="destination-category" key={category}>
            <div className="category-header">
              <h2>{category}</h2>
              <span className="location-count">{trips.length} locations</span>
            </div>
            <div className="travel-grid">
              {trips.map((trip) => (
                <TravelCard
                  imageSrc = {trip.imageSrc}
                  key={trip.id}
                  ref={(el) => (tripRefs.current[trip.id] = el)}
                  {...trip}
                />
              ))}
            </div>
          </div>
        ))}
      </main>
    </div>
  );
};

export default Travel;
