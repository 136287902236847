import React, { useRef, useEffect } from 'react';
import './EducationItem.css';

const EducationItem = ({ education, isActive, onSelect }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (isActive && contentRef.current) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else if (contentRef.current) {
      contentRef.current.style.maxHeight = '0';
    }
  }, [isActive]);

  const handleClick = (e) => {
    // Prevent expansion when clicking on links
    if (e.target.tagName.toLowerCase() === 'a') {
      return;
    }
    onSelect(education.id);
  };

  return (
    <div
      className={`education-item ${isActive ? 'active' : ''}`}
      id={`education-${education.id}`}
      onClick={handleClick}
    >
      <img src={education.logo} alt={`${education.institutionEn} Logo`} className="school-logo" />
      <div className="education-content">
        <h3>{education.institutionEn}</h3>
        <h3>{education.institutionZh}</h3>
        <div className="year">{education.yearRange}</div>
        <h4>{education.degree}</h4>
        <div className="event-item">Click to see more below</div>
        <div className="expanded-content" ref={contentRef}>
          {education.officialInfo && (
            <div className="event-item">
              <h4>Official Information</h4>
              <p dangerouslySetInnerHTML={{ __html: education.officialInfo }}></p>
            </div>
          )}

          {education.extracurricular && education.extracurricular.length > 0 && (
            <div className="event-item">
              <h4>Extracurricular</h4>
              <ul>
                {education.extracurricular.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}

          {education.capstone && education.capstone.length > 0 && (
            <div className="event-item">
              <h4>Final Year Capstone</h4>
              <ul>
                {education.capstone.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}

          {education.achievements && education.achievements.length > 0 && (
            <div className="event-item">
              <h4>Achievements</h4>
              <ul>
                {education.achievements.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}

          {education.research && education.research.length > 0 && (
            <div className="event-item">
              <h4>Research Project</h4>
              <ul>
                {education.research.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}

          {education.subjects && education.subjects.length > 0 && (
            <div className="event-item">
              <h4>Subjects</h4>
              <ul>
                {education.subjects.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}

          {education.additionalInfo && education.additionalInfo.length > 0 && (
            <div className="event-item">
              {education.additionalInfo.map((info, index) => (
                <p key={index}>{info}</p>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EducationItem;
