import React from 'react';
import TravelBlogPage from '../../components/TravelBlog/TravelBlogPage';

import MotelImg from '../../assets/images/Travel/Great Ocean Road/Motel.jpg';
import MotelRoomImg from '../../assets/images/Travel/Great Ocean Road/MotelRoom.jpg';
import MotelDogImg from '../../assets/images/Travel/Great Ocean Road/MotelDog.jpg';
import TwelveApostlesImg from '../../assets/images/Travel/Great Ocean Road/twelve apostles.jpg';
import BreakfastImg from '../../assets/images/Travel/Great Ocean Road/Breakfast.jpg';
import KoalaImg from '../../assets/images/Travel/Great Ocean Road/Koala.jpg';
import PortCampbellImg from '../../assets/images/Travel/Great Ocean Road/Port Campbell.jpg';
import PortCampbell2Img from '../../assets/images/Travel/Great Ocean Road/PortCampbell-2.jpg';
import RedForestImg from '../../assets/images/Travel/Great Ocean Road/RedForest.jpg';


const accommodations = [
  {
    // title: "Seaside Resort",
    topic: "Twelve Apostles Motel & Country Retreat",
    description: "一間離十二使徒較近的Motel。房間品質普通，價格也不是特別便宜，不過能在附近找到的選項也不多，所以整體還算可以接受。這間的老闆養了一隻很親人的薩摩耶，非常可愛。",
    bulletPoints: [
      "地址: 314 Booringa Rd, Princetown VIC 3269",
      "推薦指數: 4/5"
    ],
    images: [
      { src: MotelImg, alt: "Motel" },
      { src: MotelRoomImg, alt: "MotelRoom" },
    ]
  }
];


const GreatOceanRoadTravelBlog = () => {
  const greatOceanRoadTripData = {
    title: "Great Ocean Road - 大洋路二日遊",
    subtitle: "穿梭在紅杉林間，馳騁於海岸線，落日與十二使徒",
    accommodation: accommodations, // Use a colon instead of an equals sign
    
    featuredImage: {
      src: TwelveApostlesImg,
      alt: "Twelve Apostles",
      caption: "Twelve Apostles 著名的十二使徒岩(剩七個)"
    },
    
    journeyIntro: "感謝好朋友軒光的邀約，我們踏上了大洋路之旅。這是一次二日遊，輪流開車殺向墨爾本西南方的Geelong以及大洋路。先經過山路、神木紅杉林、溪流小徑、無尾熊棲息地等等，最後來到了大洋路的重點:十二使徒岩。",
    
    // Updated to object format for the PhotoGallery component
    galleryImages: [
      
      {
        src: PortCampbellImg,
        alt: "Willies Creek",
        caption: "很美吧"
      },
      {
        src: KoalaImg,
        alt: "PenguinCentreSelfie",
        caption: "野生無尾熊"
      },
      {
        src: PortCampbell2Img,
        alt: "WorkingDogShow",
        caption: "這裡也滿美的"
      }
    ],
    
    travelPlan: [
      {
        day: 1,
        activities: [
          {text: '一早去Hertz領車前往，在Geelong停了一下吃了個早餐。接著繼續前往大洋路。' },
          {text: '途中停了幾個點，包含一個有野生無尾熊的步道、一個紅杉林神木區，以及一個據說有鴨嘴獸的瀑布。' },
          {text: '離開了山路後，沿著海岸線繼續開，往十二使徒岩的方向開。' },
          {text: '十二使徒岩附近的住宿與餐廳選項比較少，將就著一晚，其實也還行。' },
          {text: '在十二使徒前看著落日，算是在澳洲旅行清單上打了個勾' }
        ]
      },
      {
        day: 2,
        activities: [
          {text: '第二天早上在Port Campbell的一家咖啡廳吃早餐，我點的酪梨吐司很好吃(比我自己平常做的好吃不少)。'},
          {text: '前往Tower Hill Wildlife Reserve，看到野生Emu。'},
          {text: '沿途繼續在漂亮的海岸線尋找拍照點，慢慢開回去。'},
        ]
      }
    ],
    
    // You can also replace highlights with another PhotoGallery if desired.
    // For consistency, we'll convert highlightImages to the new format as well.
    highlights: {
      text: "大洋路的重點應該是十二使徒岩的落日。我們及時趕到，看著染成橘色的天空與使徒岩構成的景象，很難想像如此景色不是在照片上看到而是真的在眼前。",
      
    },
    highlightsGalleryImages: [
      {
        src: MotelDogImg,
        alt: "MotelDog",
        caption: "老闆養的薩摩耶"
      },
      {
        src: BreakfastImg,
        alt: "PenguinParade",
        caption: "早餐:超好吃羊奶酪起司酪梨吐司"
      },
      {
        src: RedForestImg,
        alt: "PenguinParade",
        caption: "紅杉林神木群"
      },
    ],


    
    finalThoughts: "大洋路感覺是墨爾本必須造訪的一個點，整體而言，還不錯。途中有一些景點讓整趟公路旅行有不少調劑，但稱不上特別出色。如果來墨爾本的時間較多，可以來一趟。",
    
    author: {
      name: "Yun Chu",
      date: "2024/12/8"
      // image: "/api/placeholder/80/80",
      // bio: "Embedded Systems Engineer and adventure enthusiast. Always seeking new experiences and sharing stories along the way."
    }
  };

  return <TravelBlogPage {...greatOceanRoadTripData} />;
};

export default GreatOceanRoadTravelBlog;
