import React from 'react';
import TravelBlogPage from '../../components/TravelBlog/TravelBlogPage';

import QuokkaImg from '../../assets/images/Travel/Perth/quokka.jpg';
import Quokka2Img from '../../assets/images/Travel/Perth/quokka-2.jpg';
import Quokka3Img from '../../assets/images/Travel/Perth/quokka-3.jpg';
import PearlImg from '../../assets/images/Travel/Perth/pearl.jpg';
import WilliesCreekImg from '../../assets/images/Travel/Perth/Willies-Creek.jpg';
import PinnaclesImg from '../../assets/images/Travel/Perth/Pinnacles.jpg';
import SamphireSunsetImg from '../../assets/images/Travel/Perth/Samphire Sunset.jpg';
import SamphireHotel from '../../assets/images/Travel/Perth/SamphireHotel.jpg';
import RottnestMapImg from '../../assets/images/Travel/Perth/Rottnest-Map.webp';
import IsolaImg from '../../assets/images/Travel/Perth/Isola.jpg';
import JigoloImg from '../../assets/images/Travel/Perth/Jigolo.jpg';


const accommodations = [
  {
    // title: "Seaside Resort",
    topic: "Day 1 - Day 4: Quay Perth Hotel",
    description: "小型商旅，空間小，位置佳",
    bulletPoints: [
      "地址: 18 The Esplanade, Perth WA 6000",
      "推薦指數: 4/5"
    ],
    images: [

    ]
  },
  {
    // title: "Mountain Lodge",
    topic: "Day 4 - Day 5: Samphire Rottnest",
    description: "Samphire真的是一間超讚的飯店。島上有其他選項，也有glamping的選項，不過我們相當喜歡Samphire的體驗，價格甚至不貴!第一天的中餐跟晚餐都在Isola吃的，非常厲害的餐廳，龍蝦油烤大蝦以及神奇的豬五花，大推!",
    bulletPoints: [
      "地址: Corner Parker Point Rd and, McCallum Ave, Rottnest Island WA 6161",
      "推薦指數: 5/5"
    ],
    images: [
      { src: SamphireSunsetImg, alt: "Lodge exterior" },
      { src: SamphireHotel, alt: "Interior of a lodge room" }
    ]
  },
  {
    // title: "Mountain Lodge",
    topic: "Day 5 - Day 6: Hyatt Regency Hotel",
    description: "凱悅是老牌飯店，但這一間的品質真的很差。房內設施老舊到不行，飯店打掃的也不行。早上的buffet還可以，可是廚師對於補上空的佐料台非常被動。個人體驗不佳，或許未來不會再住任何凱悅了。",
    
    bulletPoints: [
      "地址: 99 Adelaide Terrace, Perth WA 6000 (永久歇業了)",
      "推薦指數: 3/5"

    ],
    images: [

    ]
  }
];


const PerthTravelBlog = () => {
  const tasmaniaTripData = {
    title: "Perth之旅－Rottest Island與微笑島民",
    subtitle: "陽光、沙灘、小Quokka",
    accommodation: accommodations, // Use a colon instead of an equals sign
    
    featuredImage: {
      src: QuokkaImg,
      alt: "Quokka",
      caption: "微笑的短尾矮袋鼠"
    },
    
    journeyIntro: "Western Australia是一個與整個澳洲隔絕的地方，是礦業、商業與灌木叢組成的遙遠世界。2024年中，我們為了一睹短尾矮袋鼠，啟程踏上為了期六天的西澳之旅。經過了尖石鎮、倫敦街，以及親自體驗了一把淘珍珠的快樂，我們終於見到quokkas啦!",
    
    // Updated to object format for the PhotoGallery component
    galleryImages: [
      {
        src: PearlImg,
        alt: "Pearl",
        caption: "澳白珍珠(South Sea Pearl)"
      },
      {
        src: WilliesCreekImg,
        alt: "Willies Creek",
        caption: "現場挖珍珠!"
      },
      {
        src: PinnaclesImg,
        alt: "Pinnacles",
        caption: "沙漠中的尖石陣，原住民神話故事中的手指"
      }
    ],
    
    travelPlan: [
      {
        day: 1,
        activities: [
          { icon: 'plane-arrival', text: '飛四小時抵達Perth，前往飯店放行李' },
          { icon: 'car', text: '去倫敦街的某個Cafe吃個午餐後前往Kings Park' },
          { icon: 'car', text: '前往Balthazar吃了頓晚餐，第一天就結束啦' }
        ]
      },
      {
        day: 2,
        activities: [
          {icon: 'plane-arrival', text: '第二天是尖石陣與銀河觀賞一日遊'},
          {icon: 'plane-arrival', text: '中途有僅有下車兩趟，一次在加油站上廁所，另一次在海邊的咖啡廳短暫的小憩'},
          {icon: 'plane-arrival', text: '抵達沙漠中的尖石陣，在尖石中漫步，還巧遇袋鼠路過'},
          {icon: 'plane-arrival', text: '導遊準備了野餐食材，我們靜靜的聽著故事，然後等候夜幕的到來'},
          {icon: 'plane-arrival', text: '沒有光害的沙漠，銀河很亮眼。南十字星與南半球星空，有著完全不一樣的星座故事，與本地原住民先祖跨越時空的連結。'},
          {icon: 'plane-arrival', text: '回程，回憶'},
        ]
      },
      {
        day: 3,
        activities: [
          {icon: 'plane-arrival', text: '第三日的行程是:挖珍珠!我們前往Willies Creek Pearl的店，現場體驗了一把半賭博式的挖珍珠(這個要預約)。'},
          {icon: 'plane-arrival', text: '吃了一份新鮮扇貝料理，用工具打開了扇貝後，取出我們的珍珠。很大，很亮，不過表面有不少洞。最後我們用折抵券買了一顆小珍珠作紀念品，也是為澳洲生活買了份紀念。'},
          {icon: 'plane-arrival', text: '下午去Fremantle Market，好吃的很多，也有很多當地的藝文小物。'},
          {icon: 'plane-arrival', text: '又殺回了Kings Park購買上次沒買到的紀念金幣。'},
          {icon: 'plane-arrival', text: '晚餐吃了一家日式居酒屋Jigoro，沒想到炸山藥薯條真的是越吃越上癮。早早回家期待隔天的行程!'}
        ]
      },
      {
        day: 4,
        activities: [
          {icon: 'plane-arrival', text: '一早到達馬頭，等待上船。我們搭乘的是SeaLink，據說比較舒服，也比較不會超賣。自己體驗真的很不錯。'},
          {icon: 'plane-arrival', text: '兩小時的航程，經過了Frementle，抵達了Rottnest Island!'},
          {icon: 'plane-arrival', text: '一上島走向飯店Samphire，馬上就看到Quokka們，一隻媽媽帶著一隻幼仔。'},
          {icon: 'plane-arrival', text: '接著前往Isola吃了頓午餐，龍蝦油考大蝦、義大利麵與烤節瓜，真的超好吃(所以我們晚餐又跑去吃了Isola)。'},
          {icon: 'plane-arrival', text: '下午選擇徒步逛島，真的捕捉老多可愛的瞬間。慢慢觀察短尾矮袋鼠們的日常。'},
          {icon: 'plane-arrival', text: '散步完，我們預約了按摩(有點奢侈)。'},
          {icon: 'plane-arrival', text: '晚餐又是Isola，怎麼說呢，真的很有特色，很用心。脆皮豬五花搭配特製醬汁，再左上炸橄欖前菜，超級超級好吃。'},
          {icon: 'plane-arrival', text: '晚上在飯店外的營火堆旁看到一隻好奇的島民，超讚的夜晚。'}
        ]
      },
      {
        day: 5,
        activities: [
          {icon: 'plane-arrival', text: '島上的第二天，我們決定搭乘環島巴士繞了一圈'},
          {icon: 'plane-arrival', text: '下午坐船回到澳洲本土大陸'},
          {icon: 'plane-arrival', text: '入住凱悅飯店，然後去了一家叫EDOSEI的日本料理，是澳洲本土難得好吃的日本料理。'}
        ]
      },
      {
        day: 6,
        activities: [
          {icon: 'plane-arrival', text: '最後一天，早上起床了飯店buffet就準備回墨爾本啦!'}

        ]
      }
    ],
    
    // You can also replace highlights with another PhotoGallery if desired.
    // For consistency, we'll convert highlightImages to the new format as well.
    highlights: {
      text: "Rottnest Island絕對是超級大重點。冬季來真的很棒，一方面避開了人潮，另一方面又可以見到活躍的矮袋鼠。",
      
    },
    highlightsGalleryImages: [
      {
        src: RottnestMapImg,
        alt: "RottnestMap",
        caption: "Rottnest Island 地圖"
      },
      {
        src: Quokka2Img,
        alt: "Quokka2",
        caption: "在餐桌邊到處晃晃的小矮袋鼠"
      },
      {
        src: Quokka3Img,
        alt: "Quokka3",
        caption: "超可愛吧"
      },
      {
        src: IsolaImg,
        alt: "Isola",
        caption: "超級好吃的豬五花與炸橄欖"
      },
      {
        src: JigoloImg,
        alt: "Jigolo",
        caption: "炸山藥，第一口很普通，但莫名越吃越好吃"
      }
    ],


    
    finalThoughts: "Rottnest Island絕對值得大家跑一趟。Quokka真的很可愛，島上的風光、食物、住宿真的是好到可以當作蜜月地點的水準。Isola的美食與Samphire的舒適，大推大推。整體而言，這趟西澳行很舒服愉快，如果時間允許的話，未來以可以到西澳北部去體驗大自然的各種奇妙工法。",
    
    author: {
      name: "Yun Chu",
      date: "2024/12/7"
      // image: "/api/placeholder/80/80",
      // bio: "Embedded Systems Engineer and adventure enthusiast. Always seeking new experiences and sharing stories along the way."
    }
  };

  return <TravelBlogPage {...tasmaniaTripData} />;
};

export default PerthTravelBlog;
